import React from "react";
import { IRound, IToken } from "../../common/interfaces";
import Token from "../token";

interface MyComponentProps {
  allTokens: IToken[];
  currentRound: IRound | undefined;
}

const MasterCard: React.FC<MyComponentProps> = ({
  allTokens,
  currentRound,
}) => {
  return (
    <div className="grid overflow-y-auto grid-cols-5 w-full p-2 bg-white">
      {Array.from({ length: Math.ceil(allTokens.length / 5) }, (_, rowIndex) =>
        allTokens
          .slice(rowIndex * 5, rowIndex * 5 + 5)
          .map((token, index) => (
            <Token
              key={index}
              name={token.name}
              illuminated={true}
              checked={currentRound?.drawnTokens.some(
                (drawnToken) => drawnToken.id === token.id,
              )}
              className="w-14"
            />
          )),
      )}
    </div>
  );
};

export default MasterCard;
