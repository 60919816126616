import React from "react";

interface MyComponentProps {
  winningCombination: number[];
}

const WinningExampleCard: React.FC<MyComponentProps> = ({
  winningCombination,
}) => {
  const gridSize = 25;

  return (
    <div className="w-full place-items-center mt-3">
      <div
        className="grid grid-cols-5 w-[200px] gap-1 p-1 bg-customBrown-500 rounded-lg border-gray-200"
        style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", color: "black" }}
      >
        {Array.from({ length: gridSize }, (_, index) => (
          <div
            key={index}
            className={`relative flex flex-col aspect-1 items-center justify-center border p-2 border-gray-400 rounded-lg ${
              winningCombination.includes(index)
                ? "bg-blue-400 text-white"
                : "bg-gray-700 text-gray-300"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default WinningExampleCard;
