import React, { useState } from "react";
import { useSocket } from "../../common/contexts/useSocket";

interface MyComponentProps {
  setIsPopUpSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopUpSelector: React.FC<MyComponentProps> = ({
  setIsPopUpSelectorOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState<"video" | "html">(
    "video",
  );
  const [data, setData] = useState("");
  const { socket, connectionStatus } = useSocket();

  const handleSend = () => {
    if (connectionStatus === "connected") {
      socket.emit("popUp", { type: selectedOption, data: data });
      setIsPopUpSelectorOpen(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-black">
      <div className="w-80 p-4 border rounded-lg shadow-lg bg-white">
        <div className="w-[100%] flex justify-end">
          {/* Botón de eliminar */}
          <button
            onClick={() => setIsPopUpSelectorOpen(false)}
            className="top-6 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-sm"
          >
            Cerrar
          </button>
        </div>
        <h2 className="text-lg font-semibold text-center mb-4">
          Tipo de Popup
        </h2>
        <div className="flex justify-center gap-4 mb-4">
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="media"
              value="video"
              checked={selectedOption === "video"}
              onChange={() => setSelectedOption("video")}
            />
            <span>Video</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              name="media"
              value="html"
              checked={selectedOption === "html"}
              onChange={() => setSelectedOption("html")}
            />
            <span>HTML</span>
          </label>
        </div>

        {selectedOption === "video" && (
          <input
            type="text"
            placeholder="URL del video"
            value={data}
            onChange={(e) => setData(e.target.value)}
            className="w-full p-2 border rounded-md text-sm"
          />
        )}

        {selectedOption === "html" && (
          <textarea
            placeholder="Contenido HTML"
            value={data}
            onChange={(e) => setData(e.target.value)}
            className="w-full p-2 border rounded-md text-sm h-20"
          />
        )}

        <div className="flex justify-end mt-4">
          <button
            onClick={handleSend}
            className="px-4 py-2 bg-turquoise-500 text-white rounded-md"
          >
            Difundir
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpSelector;
