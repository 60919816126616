import { IToken, SpritePositions } from "./interfaces";

export const tokensSpriteUrl = `/sprite_tokens.png`;

export const currencies = [
  { code: "VED", name: "Bolívares" },
  { code: "USD", name: "Dólares" },
  { code: "COP", name: "Peso Colombianos" },
  { code: "PEN", name: "Soles" },
  { code: "EUR", name: "Euros" },
];

export const spritePositions: SpritePositions = {
  Abanico: { x: 1, y: 1 },
  Aguacate: { x: 123, y: 1 },
  Aguja_con_hilo: { x: 1, y: 123 },
  Ajo: { x: 123, y: 123 },
  Alicate: { x: 245, y: 1 },
  Ambulancia: { x: 245, y: 123 },
  Ancla: { x: 1, y: 245 },
  Anillo: { x: 123, y: 245 },
  Anzuelo: { x: 245, y: 245 },
  Araña: { x: 367, y: 1 },
  Árbol: { x: 367, y: 123 },
  Arcoíris: { x: 367, y: 245 },
  Ardilla: { x: 1, y: 367 },
  Arpa: { x: 123, y: 367 },
  Auyama: { x: 245, y: 367 },
  Avión: { x: 367, y: 367 },
  Balde: { x: 489, y: 1 },
  Bandera: { x: 489, y: 245 },
  Ballena: { x: 489, y: 123 },
  Barco: { x: 489, y: 367 },
  Barquilla: { x: 1, y: 489 },
  Bate: { x: 123, y: 489 },
  Berenjena: { x: 245, y: 489 },
  Biberón: { x: 367, y: 489 },
  Bicicleta: { x: 489, y: 489 },
  Boca: { x: 611, y: 1 },
  Bombillo: { x: 611, y: 123 },
  Bota: { x: 611, y: 245 },
  Botella: { x: 611, y: 367 },
  Brocha: { x: 611, y: 489 },
  Brócoli: { x: 1, y: 611 },
  Brújula: { x: 123, y: 611 },
  Burro: { x: 245, y: 611 },
  Caballo: { x: 367, y: 611 },
  Cabeza_de_hombre: { x: 489, y: 611 },
  Cacao: { x: 611, y: 611 },
  Caja_de_herramientas: { x: 733, y: 1 },
  Caja: { x: 733, y: 123 },
  Calabacín: { x: 733, y: 245 },
  Cambur: { x: 733, y: 367 },
  Camello: { x: 733, y: 489 },
  Candado: { x: 123, y: 733 },
  Campana: { x: 1, y: 733 },
  Camisa: { x: 733, y: 611 },
  Cañón: { x: 245, y: 733 },
  Capa: { x: 367, y: 733 },
  Capullos: { x: 489, y: 733 },
  Caracol: { x: 611, y: 733 },
  Carro: { x: 733, y: 733 },
  Célery: { x: 855, y: 1 },
  Cereza: { x: 855, y: 123 },
  Chirero: { x: 855, y: 245 },
  Chupón: { x: 855, y: 367 },
  Ciempiés: { x: 855, y: 489 },
  Cincel: { x: 855, y: 611 },
  Ciruela: { x: 855, y: 733 },
  Clavos: { x: 1, y: 855 },
  Coco: { x: 123, y: 855 },
  Cocodrilo: { x: 245, y: 855 },
  Cofre: { x: 367, y: 855 },
  Cohete: { x: 489, y: 855 },
  Coliflor: { x: 611, y: 855 },
  Corazón: { x: 733, y: 855 },
  Corbata: { x: 855, y: 855 },
  Cruz: { x: 977, y: 1 },
  Cuatro: { x: 977, y: 123 },
  Dado: { x: 977, y: 367 },
  Culebra: { x: 977, y: 245 },
  Dedo: { x: 977, y: 489 },
  Delfín: { x: 977, y: 611 },
  Destornillador: { x: 977, y: 733 },
  Dólar: { x: 977, y: 855 },
  Durazno: { x: 1, y: 977 },
  Elefante: { x: 123, y: 977 },
  Escalera: { x: 245, y: 977 },
  Escoba: { x: 367, y: 977 },
  Escopeta: { x: 489, y: 977 },
  Escuadra: { x: 611, y: 977 },
  Espátula: { x: 733, y: 977 },
  Estrella: { x: 855, y: 977 },
  Exacto: { x: 977, y: 977 },
  Florero: { x: 1099, y: 1 },
  Fresa: { x: 1099, y: 123 },
  Gallina: { x: 1099, y: 245 },
  Gallo: { x: 1099, y: 367 },
  Gato: { x: 1099, y: 489 },
  Gavilán: { x: 1099, y: 611 },
  Gorra: { x: 1099, y: 733 },
  Granada: { x: 1099, y: 855 },
  Grapadora: { x: 1099, y: 977 },
  Guacamaya: { x: 1, y: 1099 },
  Guama: { x: 123, y: 1099 },
  Guanábana: { x: 245, y: 1099 },
  Guantes: { x: 367, y: 1099 },
  Guardia: { x: 489, y: 1099 },
  Guayaba: { x: 611, y: 1099 },
  Gusano: { x: 733, y: 1099 },
  Hacha: { x: 855, y: 1099 },
  Hormiga: { x: 977, y: 1099 },
  Huevo: { x: 1099, y: 1099 },
  Iguana: { x: 1221, y: 1 },
  Imán: { x: 1221, y: 123 },
  Jarra: { x: 1221, y: 245 },
  Lámpara: { x: 1221, y: 367 },
  Lápiz: { x: 1221, y: 489 },
  Lechosa: { x: 1221, y: 611 },
  Lechuga: { x: 1221, y: 733 },
  Lentes: { x: 1221, y: 855 },
  Libro: { x: 1221, y: 977 },
  Lima: { x: 1221, y: 1099 },
  Limón: { x: 1, y: 1221 },
  Linterna: { x: 123, y: 1221 },
  Llave_de_tubo: { x: 245, y: 1221 },
  Llave_inglesa: { x: 367, y: 1221 },
  Llave: { x: 489, y: 1221 },
  Lupa: { x: 611, y: 1221 },
  Machete: { x: 733, y: 1221 },
  Mamey: { x: 855, y: 1221 },
  Mandarina: { x: 977, y: 1221 },
  Mandarria: { x: 1099, y: 1221 },
  Mango: { x: 1221, y: 1221 },
  Mano: { x: 1343, y: 1 },
  Manzana: { x: 1343, y: 123 },
  Maracas: { x: 1343, y: 245 },
  Mariposa: { x: 1343, y: 367 },
  Martillo: { x: 1343, y: 489 },
  Mazorca: { x: 1343, y: 611 },
  Melón: { x: 1343, y: 733 },
  Merey: { x: 1343, y: 855 },
  Mesa: { x: 1343, y: 977 },
  Mono: { x: 1343, y: 1099 },
  Mora: { x: 1343, y: 1221 },
  Moto: { x: 1, y: 1343 },
  Muñeca: { x: 123, y: 1343 },
  Naranja: { x: 245, y: 1343 },
  Níspero: { x: 367, y: 1343 },
  Ojo: { x: 489, y: 1343 },
  Oso: { x: 611, y: 1343 },
  Pala: { x: 733, y: 1343 },
  Paloma: { x: 855, y: 1343 },
  Pantalón: { x: 977, y: 1343 },
  Papagayo: { x: 1099, y: 1343 },
  Paragua: { x: 1221, y: 1343 },
  Parchita: { x: 1343, y: 1343 },
  Patilla: { x: 1465, y: 1 },
  Pato: { x: 1465, y: 123 },
  Pavo: { x: 1465, y: 245 },
  Pelota: { x: 1465, y: 367 },
  Pera: { x: 1465, y: 489 },
  Perro: { x: 1465, y: 611 },
  Pescado: { x: 1465, y: 733 },
  Pico: { x: 1465, y: 855 },
  Pie: { x: 1465, y: 977 },
  Pimentón: { x: 1465, y: 1099 },
  Piña: { x: 1465, y: 1221 },
  Pipa: { x: 1465, y: 1343 },
  Pithaya: { x: 1, y: 1465 },
  Policía: { x: 123, y: 1465 },
  Pomalaca: { x: 245, y: 1465 },
  Puñal: { x: 367, y: 1465 },
  Racimo_de_uvas: { x: 489, y: 1465 },
  Rayo: { x: 611, y: 1465 },
  Reloj: { x: 733, y: 1465 },
  Rodillo: { x: 855, y: 1465 },
  Rosa: { x: 977, y: 1465 },
  Sapo: { x: 1099, y: 1465 },
  Segueta: { x: 1221, y: 1465 },
  Semáforo: { x: 1343, y: 1465 },
  Serrucho: { x: 1465, y: 1465 },
  Silla: { x: 1587, y: 1 },
  Sol: { x: 1587, y: 123 },
  Sombrero: { x: 1587, y: 245 },
  Tabaco: { x: 1587, y: 367 },
  Taladro: { x: 1587, y: 489 },
  Tamarindo: { x: 1587, y: 611 },
  Tambor: { x: 1587, y: 733 },
  Taxi: { x: 1587, y: 855 },
  Taza: { x: 1587, y: 977 },
  Teléfono: { x: 1587, y: 1099 },
  Tenaza: { x: 1587, y: 1221 },
  Tierra: { x: 1587, y: 1343 },
  Tigre: { x: 1587, y: 1465 },
  Tijera: { x: 1, y: 1587 },
  Tomate: { x: 123, y: 1587 },
  Tornillo: { x: 245, y: 1587 },
  Toro: { x: 367, y: 1587 },
  Tortuga: { x: 489, y: 1587 },
  Trompeta: { x: 611, y: 1587 },
  Trompo: { x: 733, y: 1587 },
  Tucán: { x: 855, y: 1587 },
  Tuerca: { x: 977, y: 1587 },
  Vaca: { x: 1099, y: 1587 },
  Vaso: { x: 1221, y: 1587 },
  Vela: { x: 1343, y: 1587 },
  Ventilador: { x: 1465, y: 1587 },
  Yoyo: { x: 1587, y: 1587 },
  Yuca: { x: 1709, y: 1 },
  Zanahoria: { x: 1709, y: 123 },
  Zancudo: { x: 1709, y: 245 },
  Zapatos: { x: 1709, y: 367 },
};

export const allTokens: IToken[] = [
  {
    id: 1,
    name: "Abanico",
  },
  {
    id: 2,
    name: "Aguacate",
  },
  {
    id: 3,
    name: "Aguja con hilo",
  },
  {
    id: 4,
    name: "Ajo",
  },
  {
    id: 5,
    name: "Alicate",
  },
  {
    id: 6,
    name: "Ambulancia",
  },
  {
    id: 7,
    name: "Ancla",
  },
  {
    id: 8,
    name: "Anillo",
  },
  {
    id: 9,
    name: "Anzuelo",
  },
  {
    id: 10,
    name: "Araña",
  },
  {
    id: 200,
    name: "Árbol",
  },
  {
    id: 11,
    name: "Arcoíris",
  },
  {
    id: 12,
    name: "Ardilla",
  },
  {
    id: 13,
    name: "Arpa",
  },
  {
    id: 14,
    name: "Auyama",
  },
  {
    id: 15,
    name: "Avión",
  },
  {
    id: 16,
    name: "Balde",
  },
  {
    id: 17,
    name: "Ballena",
  },
  {
    id: 18,
    name: "Bandera",
  },
  {
    id: 19,
    name: "Barco",
  },
  {
    id: 20,
    name: "Barquilla",
  },
  {
    id: 21,
    name: "Bate",
  },
  {
    id: 22,
    name: "Berenjena",
  },
  {
    id: 23,
    name: "Biberón",
  },
  {
    id: 24,
    name: "Bicicleta",
  },
  {
    id: 25,
    name: "Boca",
  },
  {
    id: 26,
    name: "Bombillo",
  },
  {
    id: 27,
    name: "Bota",
  },
  {
    id: 28,
    name: "Botella",
  },
  {
    id: 29,
    name: "Brocha",
  },
  {
    id: 30,
    name: "Brócoli",
  },
  {
    id: 31,
    name: "Brújula",
  },
  {
    id: 32,
    name: "Burro",
  },
  {
    id: 33,
    name: "Caballo",
  },
  {
    id: 34,
    name: "Cabeza de hombre",
  },
  {
    id: 35,
    name: "Cacao",
  },
  {
    id: 37,
    name: "Caja",
  },
  {
    id: 36,
    name: "Caja de herramientas",
  },
  {
    id: 38,
    name: "Calabacín",
  },
  {
    id: 39,
    name: "Cambur",
  },
  {
    id: 40,
    name: "Camello",
  },
  {
    id: 41,
    name: "Camisa",
  },
  {
    id: 42,
    name: "Campana",
  },
  {
    id: 43,
    name: "Candado",
  },
  {
    id: 48,
    name: "Cañón",
  },
  {
    id: 44,
    name: "Capa",
  },
  {
    id: 45,
    name: "Capullos",
  },
  {
    id: 46,
    name: "Caracol",
  },
  {
    id: 47,
    name: "Carro",
  },
  {
    id: 66,
    name: "Célery",
  },
  {
    id: 49,
    name: "Cereza",
  },
  {
    id: 50,
    name: "Chirero",
  },
  {
    id: 51,
    name: "Chupón",
  },
  {
    id: 52,
    name: "Ciempiés",
  },
  {
    id: 53,
    name: "Cincel",
  },
  {
    id: 54,
    name: "Ciruela",
  },
  {
    id: 55,
    name: "Clavos",
  },
  {
    id: 56,
    name: "Coco",
  },
  {
    id: 57,
    name: "Cocodrilo",
  },
  {
    id: 58,
    name: "Cofre",
  },
  {
    id: 59,
    name: "Cohete",
  },
  {
    id: 60,
    name: "Coliflor",
  },
  {
    id: 61,
    name: "Corazón",
  },
  {
    id: 62,
    name: "Corbata",
  },
  {
    id: 63,
    name: "Cruz",
  },
  {
    id: 64,
    name: "Cuatro",
  },
  {
    id: 65,
    name: "Culebra",
  },
  {
    id: 67,
    name: "Dado",
  },
  {
    id: 68,
    name: "Dedo",
  },
  {
    id: 69,
    name: "Delfín",
  },
  {
    id: 70,
    name: "Destornillador",
  },
  {
    id: 72,
    name: "Dólar",
  },
  {
    id: 71,
    name: "Durazno",
  },
  {
    id: 73,
    name: "Elefante",
  },
  {
    id: 74,
    name: "Escalera",
  },
  {
    id: 75,
    name: "Escoba",
  },
  {
    id: 76,
    name: "Escopeta",
  },
  {
    id: 77,
    name: "Escuadra",
  },
  {
    id: 78,
    name: "Espátula",
  },
  {
    id: 79,
    name: "Estrella",
  },
  {
    id: 80,
    name: "Exacto",
  },
  {
    id: 81,
    name: "Florero",
  },
  {
    id: 82,
    name: "Fresa",
  },
  {
    id: 83,
    name: "Gallina",
  },
  {
    id: 84,
    name: "Gallo",
  },
  {
    id: 85,
    name: "Gato",
  },
  {
    id: 86,
    name: "Gavilán",
  },
  {
    id: 87,
    name: "Gorra",
  },
  {
    id: 88,
    name: "Granada",
  },
  {
    id: 89,
    name: "Grapadora",
  },
  {
    id: 90,
    name: "Guacamaya",
  },
  {
    id: 91,
    name: "Guama",
  },
  {
    id: 93,
    name: "Guanábana",
  },
  {
    id: 92,
    name: "Guantes",
  },
  {
    id: 94,
    name: "Guardia",
  },
  {
    id: 95,
    name: "Guayaba",
  },
  {
    id: 96,
    name: "Gusano",
  },
  {
    id: 97,
    name: "Hacha",
  },
  {
    id: 98,
    name: "Hormiga",
  },
  {
    id: 99,
    name: "Huevo",
  },
  {
    id: 100,
    name: "Iguana",
  },
  {
    id: 101,
    name: "Imán",
  },
  {
    id: 102,
    name: "Jarra",
  },
  {
    id: 114,
    name: "Lámpara",
  },
  {
    id: 115,
    name: "Lápiz",
  },
  {
    id: 103,
    name: "Lechosa",
  },
  {
    id: 104,
    name: "Lechuga",
  },
  {
    id: 105,
    name: "Lentes",
  },
  {
    id: 106,
    name: "Libro",
  },
  {
    id: 107,
    name: "Lima",
  },
  {
    id: 108,
    name: "Limón",
  },
  {
    id: 109,
    name: "Linterna",
  },
  {
    id: 112,
    name: "Llave",
  },
  {
    id: 110,
    name: "Llave de tubo",
  },
  {
    id: 111,
    name: "Llave inglesa",
  },
  {
    id: 113,
    name: "Lupa",
  },
  {
    id: 116,
    name: "Machete",
  },
  {
    id: 117,
    name: "Mamey",
  },
  {
    id: 118,
    name: "Mandarina",
  },
  {
    id: 119,
    name: "Mandarria",
  },
  {
    id: 120,
    name: "Mango",
  },
  {
    id: 121,
    name: "Mano",
  },
  {
    id: 122,
    name: "Manzana",
  },
  {
    id: 123,
    name: "Maracas",
  },
  {
    id: 124,
    name: "Mariposa",
  },
  {
    id: 125,
    name: "Martillo",
  },
  {
    id: 126,
    name: "Mazorca",
  },
  {
    id: 127,
    name: "Melón",
  },
  {
    id: 128,
    name: "Merey",
  },
  {
    id: 129,
    name: "Mesa",
  },
  {
    id: 130,
    name: "Mono",
  },
  {
    id: 131,
    name: "Mora",
  },
  {
    id: 132,
    name: "Moto",
  },
  {
    id: 133,
    name: "Muñeca",
  },
  {
    id: 134,
    name: "Naranja",
  },
  {
    id: 135,
    name: "Níspero",
  },
  {
    id: 136,
    name: "Ojo",
  },
  {
    id: 137,
    name: "Oso",
  },
  {
    id: 138,
    name: "Pala",
  },
  {
    id: 139,
    name: "Paloma",
  },
  {
    id: 140,
    name: "Pantalón",
  },
  {
    id: 141,
    name: "Papagayo",
  },
  {
    id: 142,
    name: "Paragua",
  },
  {
    id: 143,
    name: "Parchita",
  },
  {
    id: 144,
    name: "Patilla",
  },
  {
    id: 145,
    name: "Pato",
  },
  {
    id: 146,
    name: "Pavo",
  },
  {
    id: 147,
    name: "Pelota",
  },
  {
    id: 148,
    name: "Pera",
  },
  {
    id: 149,
    name: "Perro",
  },
  {
    id: 150,
    name: "Pescado",
  },
  {
    id: 151,
    name: "Pico",
  },
  {
    id: 152,
    name: "Pie",
  },
  {
    id: 153,
    name: "Pimentón",
  },
  {
    id: 156,
    name: "Piña",
  },
  {
    id: 154,
    name: "Pipa",
  },
  {
    id: 155,
    name: "Pithaya",
  },
  {
    id: 157,
    name: "Policía",
  },
  {
    id: 158,
    name: "Pomalaca",
  },
  {
    id: 159,
    name: "Puñal",
  },
  {
    id: 160,
    name: "Racimo de uvas",
  },
  {
    id: 161,
    name: "Rayo",
  },
  {
    id: 162,
    name: "Reloj",
  },
  {
    id: 163,
    name: "Rodillo",
  },
  {
    id: 164,
    name: "Rosa",
  },
  {
    id: 165,
    name: "Sapo",
  },
  {
    id: 166,
    name: "Segueta",
  },
  {
    id: 167,
    name: "Semáforo",
  },
  {
    id: 168,
    name: "Serrucho",
  },
  {
    id: 169,
    name: "Silla",
  },
  {
    id: 170,
    name: "Sol",
  },
  {
    id: 171,
    name: "Sombrero",
  },
  {
    id: 172,
    name: "Tabaco",
  },
  {
    id: 173,
    name: "Taladro",
  },
  {
    id: 174,
    name: "Tamarindo",
  },
  {
    id: 175,
    name: "Tambor",
  },
  {
    id: 176,
    name: "Taxi",
  },
  {
    id: 177,
    name: "Taza",
  },
  {
    id: 178,
    name: "Teléfono",
  },
  {
    id: 179,
    name: "Tenaza",
  },
  {
    id: 180,
    name: "Tierra",
  },
  {
    id: 181,
    name: "Tigre",
  },
  {
    id: 182,
    name: "Tijera",
  },
  {
    id: 183,
    name: "Tomate",
  },
  {
    id: 184,
    name: "Tornillo",
  },
  {
    id: 185,
    name: "Toro",
  },
  {
    id: 186,
    name: "Tortuga",
  },
  {
    id: 187,
    name: "Trompeta",
  },
  {
    id: 188,
    name: "Trompo",
  },
  {
    id: 189,
    name: "Tucán",
  },
  {
    id: 190,
    name: "Tuerca",
  },
  {
    id: 191,
    name: "Vaca",
  },
  {
    id: 192,
    name: "Vaso",
  },
  {
    id: 193,
    name: "Vela",
  },
  {
    id: 194,
    name: "Ventilador",
  },
  {
    id: 195,
    name: "Yoyo",
  },
  {
    id: 196,
    name: "Yuca",
  },
  {
    id: 197,
    name: "Zanahoria",
  },
  {
    id: 198,
    name: "Zancudo",
  },
  {
    id: 199,
    name: "Zapatos",
  },
];
