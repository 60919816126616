import React, { useState, useEffect, useCallback } from "react";
import {
  IDrawnTokenEvent,
  IPlayerGame,
  IRound,
  IToken,
} from "../../common/interfaces";
import { speak } from "../../common/functions";
import fireAnimation from "../../lottie-animations/hot.json";
import Lottie from "lottie-react";
import Token from "../token";

interface MyComponentProps {
  playerId: number;
  setPlayerGame: React.Dispatch<React.SetStateAction<IPlayerGame | undefined>>;
  allTokens: IToken[];
  drawnTokenEvent?: IDrawnTokenEvent;
  currentRound?: IRound;
  setIsCardWinner: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const getRandomIndex = (length: number) => {
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] % length;
};

const DrawerVisor: React.FC<MyComponentProps> = ({
  playerId,
  allTokens,
  drawnTokenEvent,
  currentRound,
  setPlayerGame,
  setIsCardWinner,
}) => {
  const [visibleToken, setVisibleToken] = useState<IToken>();

  const speech = useCallback(
    (drawnTokenEvent: IDrawnTokenEvent) => {
      if ("speechSynthesis" in window) {
        const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

        if (lastDrawnToken) {
          var speech = lastDrawnToken.name;

          if (drawnTokenEvent.winners.length > 0) {
            speech += ", y han cantado lotería. ";

            const IAmWinner = drawnTokenEvent.winners.some(
              (winner) => winner.id === playerId,
            );

            if (drawnTokenEvent.winners.length === 1) {
              if (IAmWinner) {
                speech +=
                  "Felicidades, has ganado con el cartón número " +
                  drawnTokenEvent.winners[0].cards[0].serial +
                  ". ";
              } else {
                speech +=
                  drawnTokenEvent.winners[0].name +
                  " ha ganado con el cartón número " +
                  drawnTokenEvent.winners[0].cards[0].serial +
                  ". ";
              }
            } else if (drawnTokenEvent.winners.length > 1) {
              if (IAmWinner) {
                speech +=
                  "Felicidades, has ganado con el cartón número " +
                  drawnTokenEvent.winners[0].cards[0].serial +
                  ". ";
                speech +=
                  "Tambien hay otros " +
                  (drawnTokenEvent.winners.length - 1) +
                  " ganadores. ";
              } else {
                speech +=
                  "Hay " + drawnTokenEvent.winners.length + " ganadores. ";
              }

              for (var i = 0; i < drawnTokenEvent.winners.length - 1; i++) {
                speech +=
                  drawnTokenEvent.winners[i].name +
                  " ha ganado con el cartón número " +
                  drawnTokenEvent.winners[i].cards[0].serial +
                  ", ";
              }
              speech +=
                "y " +
                drawnTokenEvent.winners.at(-1)?.name +
                " ha ganado con el cartón número " +
                drawnTokenEvent.winners.at(-1)?.cards[0].serial +
                ". ";
            }

            if (drawnTokenEvent.endDate) {
              speech += "La lotería ha terminado.";
            } else {
            }
          }
          speak(speech);
        }
      }
    },
    [playerId],
  );

  useEffect(() => {
    let startFakeShufflerInterval: NodeJS.Timeout;
    let stopFakeShufflerTimeout: NodeJS.Timeout;

    if (drawnTokenEvent) {
      // Ejecutar el fake shuffler
      const notDrawnTokens = allTokens.filter(
        (token) =>
          !currentRound?.drawnTokens.some(
            (drawnToken) => drawnToken.id === token.id,
          ),
      );

      startFakeShufflerInterval = setInterval(() => {
        const randomIndex = getRandomIndex(notDrawnTokens.length);
        setVisibleToken(notDrawnTokens[randomIndex]);
      }, 100);

      // Detenemos el fake shuffler después de 2 segundos
      stopFakeShufflerTimeout = setTimeout(() => {
        // Actualizamos los drawnTokens y las victorias del round.
        setPlayerGame((prevPlayerGame) => {
          if (prevPlayerGame) {
            const updatedRounds = prevPlayerGame.lottery.rounds.map((round) =>
              round.id === drawnTokenEvent.roundId
                ? {
                    ...round,
                    drawnTokens: drawnTokenEvent.drawnTokens,
                    victories: drawnTokenEvent.winners,
                  }
                : round,
            );

            return {
              ...prevPlayerGame,
              lottery: {
                ...prevPlayerGame.lottery,
                rounds: updatedRounds,
                endDate: drawnTokenEvent.endDate
                  ? drawnTokenEvent.endDate
                  : prevPlayerGame.lottery.endDate,
              },
            };
          }
        });

        // Stop fake shuffler
        clearInterval(startFakeShufflerInterval);
        setVisibleToken(drawnTokenEvent?.drawnTokens.at(-1));
        speech(drawnTokenEvent!);

        if (drawnTokenEvent.winners.length > 0) {
          // Mostramos la pantalla de ganador 1s mas tarde de cuando se detiene el fake shuffler.
          setTimeout(() => {
            const IAmWinner = drawnTokenEvent.winners.some(
              (winner) => winner.id === playerId,
            );
            if (IAmWinner) {
              setIsCardWinner(true);
            } else {
              setIsCardWinner(false);
            }
            setVisibleToken(undefined);
          }, 1000);
        }
      }, 2000);

      return () => {
        if (startFakeShufflerInterval) clearInterval(startFakeShufflerInterval);
        if (stopFakeShufflerTimeout) clearTimeout(stopFakeShufflerTimeout);
      };
    }
  }, [
    drawnTokenEvent,
    playerId,
    allTokens,
    speech,
    setIsCardWinner,
    setPlayerGame,
  ]);

  return (
    <div className="flex flex-col">
      {/* Fila superior */}
      <div className="flex w-full">
        {/* Columna 1 */}
        <div className="w-1/2 flex flex-col items-center justify-center">
          <img src="/guacamayas.png" alt="Guacamayas" className="max-w-28" />
          {currentRound && (
            <div className="text-center text-sm">
              <div className="">RONDA ACTUAL</div>
              <div>{currentRound.winningCombination.name}</div>
              <div>{currentRound.award}</div>
            </div>
          )}
        </div>

        {/* Columna 2 */}
        <div className="w-1/2 flex flex-col items-center justify-center border-red-700 border-2 rounded-lg bg-white p-2">
          {currentRound && (
            <div className="text-black text-sm">
              Van {currentRound.drawnTokens.length || 0} de {allTokens.length}{" "}
              fichas
            </div>
          )}
          {visibleToken && (
            <Token
              name={visibleToken?.name}
              illuminated={true}
              className="w-20 object-contain"
            />
          )}
        </div>
      </div>

      {/* Fila inferior */}
      <div className="relative flex mt-1 whitespace-nowrap">
        {drawnTokenEvent?.hots &&
          drawnTokenEvent?.hots.length > 0 &&
          `${drawnTokenEvent?.hots.length} |`}
        <div className="flex overflow-x-scroll ml-1">
          {/* Jugadores Esperando */}
          {drawnTokenEvent?.hots?.map((hot, index) => (
            <span
              key={index}
              className="flex items-center justify-center mx-2 text-sm"
            >
              <Lottie
                animationData={fireAnimation}
                loop={true}
                initialSegment={[0, 25]}
                className="w-4 mr-1"
              />
              {hot.playerName}({hot.cardId})
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DrawerVisor;
