import React, { useState } from "react";
import WinningExampleCard from "../components/winning_example_card";
import { useNavigate } from "react-router-dom";
import { fetchFromServer } from "../common/functions";
import { currencies } from "../common/consts";

interface WinningCombination {
  id: number;
  name: string;
  combination: number[];
}

interface LotteryRoundDto {
  award: string;
  winningCombination: WinningCombination;
}

interface LotteryDto {
  name: string;
  cardsQty: number;
  currency: string;
  cardPrice: number;
  rounds: LotteryRoundDto[];
  earlyPayDraw: boolean;
  earlyPayDrawAward: string;
  earlyPayDrawLimitDate: string;
  cardSerialDraw: boolean;
  cardSerialDrawAward?: string;
  drawDate: string;
  terms: boolean;
}

const winningCombinations = [
  { id: 1, name: "En L", combination: [0, 5, 10, 15, 20, 21, 22, 23, 24] },
  { id: 2, name: "En X", combination: [0, 6, 12, 18, 24, 20, 16, 8, 4] },
  { id: 3, name: "En T", combination: [0, 1, 2, 3, 4, 7, 12, 17, 22] },
  {
    id: 4,
    name: "Signo de Suma",
    combination: [2, 7, 12, 17, 22, 10, 11, 13, 14],
  },
  { id: 5, name: "En Cruz", combination: [2, 7, 12, 17, 22, 5, 6, 8, 9] },
  { id: 50, name: "X Pequeña", combination: [6, 8, 12, 16, 18] },
  { id: 51, name: "Signo de Suma Pequeño", combination: [7, 11, 12, 13, 17] },
  { id: 52, name: "T Pequeña", combination: [6, 7, 8, 12, 17] },
  { id: 53, name: "Diagonal", combination: [4, 8, 12, 16, 20] },
  { id: 54, name: "Línea Horizontal", combination: [10, 11, 12, 13, 14] },
  { id: 55, name: "Línea Vertical", combination: [2, 7, 12, 17, 22] },
  {
    id: 99,
    name: "Cartón Lleno",
    combination: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24,
    ],
  },
];

const CreateLotteryPage: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const defaultCombination = winningCombinations[0];

  const [formData, setFormData] = useState<LotteryDto>({
    name: "",
    cardsQty: 5,
    currency: "VED",
    cardPrice: 1,
    terms: false,
    rounds: [
      {
        award: "",
        winningCombination: winningCombinations[0],
      },
    ],
    drawDate: "",
    earlyPayDraw: true,
    earlyPayDrawAward: "",
    earlyPayDrawLimitDate: "",
    cardSerialDraw: true,
    cardSerialDrawAward: "",
  });

  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoundChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    const updatedRounds = [...formData.rounds];
    updatedRounds[index] = { ...updatedRounds[index], [name]: value };
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const handleRemoveRound = (index: number) => {
    const updatedRounds = [...formData.rounds];
    updatedRounds.splice(index, 1);
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const handleWinningCombinationChange = (
    index: number,
    selectedWinningCombination: WinningCombination,
  ) => {
    const updatedRounds = [...formData.rounds];
    updatedRounds[index] = {
      ...updatedRounds[index],
      winningCombination: selectedWinningCombination,
    };
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const addRound = () => {
    setFormData((prev) => ({
      ...prev,
      rounds: [
        ...prev.rounds,
        { award: "", winningCombination: defaultCombination },
      ],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      setErrors([]);
      const response = await fetchFromServer(
        "POST",
        `/lotteries`,
        formData,
        "organizer",
        navigate,
      );

      if (response.ok) {
        navigate("/organizador/loterias");
      } else {
        const result = await response.json();

        if (Array.isArray(result.message)) {
          setErrors(result.message);
        } else {
          setErrors([result.message]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className="min-h-dvh"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        display: "flex",
        flexDirection: "column", // Organización vertical
        justifyContent: "flex-start", // Header en la parte superior
        alignItems: "center", // Alineación centrada horizontalmente
      }}
    >
      {/* Header */}
      <header className="w-full bg-white shadow-md p-4 flex justify-between items-center fixed z-50">
        <button
          type="button"
          onClick={() => navigate("/organizador/loterias")}
          className="text-green-500 hover:text-green-700 font-bold"
        >
          Atrás
        </button>
        <h2 className="text-lg font-semibold text-gray-700">
          Crear Nueva Lotería
        </h2>
        <div className="w-8" /> {/* Espaciador para balancear el botón */}
      </header>

      {/* Contenido principal */}
      <div
        className="flex justify-center items-center w-full mt-16"
        style={{
          flex: 1, // Asegura que el contenido llene el espacio disponible debajo del header
        }}
      >
        <form
          onSubmit={handleSubmit}
          className="w-[90%] md:w-2/3 lg:w-1/2 space-y-6 bg-white p-6 shadow-lg rounded-lg my-4"
        >
          {/* Campos principales */}
          <div className="space-y-4">
            <div>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Nombre de la Lotería"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor={`cardsQty`}
                  className="block text-sm font-medium text-gray-700 mt-4"
                >
                  # de Cartones
                </label>
                <input
                  type="number"
                  id="cardsQty"
                  name="cardsQty"
                  value={formData.cardsQty}
                  min={5}
                  max={30000}
                  maxLength={5}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
              </div>
              <div>
                <label
                  htmlFor={`cardPrice`}
                  className="block text-sm font-medium text-gray-700 mt-4"
                >
                  Precio
                </label>
                <input
                  type="number"
                  min="1"
                  id="cardPrice"
                  name="cardPrice"
                  maxLength={5}
                  placeholder="Precio"
                  value={formData.cardPrice}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="currency"
                className="block text-sm font-medium text-gray-700"
              >
                Divisa
              </label>
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Seleccione una divisa</option>
                {currencies.map((currency) => (
                  <option key={currency.code} value={currency.code}>
                    {currency.name}
                  </option>
                ))}
              </select>
            </div>
            {/* COMENTADO hasta que terceros puedan utilizar la plataforma
            {formData.cardsQty && formData.cardPrice && (
              <div className="text-red-500">
                Guacamayalote cobra 15% del total de cartones vendidos. Ejemplo:{" "}
                {formData.cardsQty} cartones vendidos a {formData.cardPrice}{" "}
                {formData.currency}, tendras que pagar{" "}
                {(formData.cardsQty * formData.cardPrice * 0.15).toFixed(2)}{" "}
                {formData.currency} para iniciar la lotería.
              </div>
            )}
              */}
          </div>

          {/* Fecha del sorteo */}
          <div>
            <label
              htmlFor="drawDate"
              className="block text-sm font-medium text-gray-700"
            >
              Fecha del Sorteo
            </label>
            <input
              type="datetime-local"
              id="drawDate"
              name="drawDate"
              value={formData.drawDate}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>

          {/* Sorteo Pronto Pago */}
          <div>
            <label
              htmlFor="cardSerialDraw"
              className="block text-sm font-medium text-gray-700"
            >
              Incluir Sorteo Pronto Pago
            </label>
            <input
              type="checkbox"
              id="earlyPayDraw"
              name="earlyPayDraw"
              checked={formData.earlyPayDraw}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.checked,
                }))
              }
              className="mt-1"
            />
            {formData.earlyPayDraw && (
              <div>
                <div className="mt-2">
                  <input
                    type="text"
                    id="earlyPayDrawAward"
                    name="earlyPayDrawAward"
                    placeholder="Premio"
                    value={formData.earlyPayDrawAward}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="earlyPayDrawLimitDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fecha Límite
                  </label>
                  <input
                    type="datetime-local"
                    id="earlyPayDrawLimitDate"
                    name="earlyPayDrawLimitDate"
                    value={formData.earlyPayDrawLimitDate}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Sorteo del serial */}
          <div>
            <label
              htmlFor="cardSerialDraw"
              className="block text-sm font-medium text-gray-700"
            >
              Incluir Sorteo del Serial del Cartón
            </label>
            <input
              type="checkbox"
              id="cardSerialDraw"
              name="cardSerialDraw"
              checked={formData.cardSerialDraw}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.checked,
                }))
              }
              className="mt-1"
            />
            {formData.cardSerialDraw && (
              <div className="mt-2">
                <input
                  type="text"
                  id="cardSerialDrawAward"
                  name="cardSerialDrawAward"
                  placeholder="Premio"
                  value={formData.cardSerialDrawAward}
                  onChange={handleChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
            )}
          </div>

          {/* Rondas */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Rondas</h3>
            {formData.rounds.map((round, index) => (
              <div key={index} className="border p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <strong>Ronda {index + 1}</strong>
                  <button
                    type="button"
                    onClick={() => handleRemoveRound(index)}
                    className={`text-red-500 hover:text-red-700 font-bold ${
                      index === 0 ? "invisible" : "visible"
                    }`}
                  >
                    X
                  </button>
                </div>
                <input
                  type="text"
                  id={`roundAward-${index}`}
                  name="award"
                  placeholder={`Premio de la ronda ${index + 1}`}
                  value={round.award}
                  onChange={(e) => handleRoundChange(index, e)}
                  required
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm"
                />

                <label
                  htmlFor={`winningCombination-${index}`}
                  className="block text-sm font-medium text-gray-700 mt-4"
                >
                  Combinación Ganadora
                </label>
                <select
                  id={`winningCombination-${index}`}
                  name="winningCombination"
                  value={round.winningCombination.id}
                  onChange={(e) => {
                    const selectedId = Number(e.target.value);
                    const combination = winningCombinations.find(
                      (comb) => comb.id === selectedId,
                    );
                    if (combination)
                      handleWinningCombinationChange(index, combination);
                  }}
                  required
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm"
                >
                  {winningCombinations.map((combination) => (
                    <option key={combination.id} value={combination.id}>
                      {combination.name}
                    </option>
                  ))}
                </select>

                {/* Componente WinningExampleCard */}
                <div className="mt-4">
                  <WinningExampleCard
                    winningCombination={round.winningCombination.combination}
                  />
                </div>
              </div>
            ))}
            {formData.rounds.length < 10 && (
              <button
                type="button"
                onClick={addRound}
                className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
              >
                Añadir Ronda
              </button>
            )}
          </div>

          {/* Checkbox obligatorio */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              checked={formData.terms}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  terms: e.target.checked,
                }))
              }
              required
              className="h-4 w-4 border-gray-300 rounded"
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium text-gray-700"
            >
              Acepto los{" "}
              <a
                href="/terminos-y-condiciones"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                términos y condiciones
              </a>
            </label>
          </div>

          {/* Errores */}
          {errors.length > 0 && (
            <div className="text-red-500 text-sm">
              {errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}

          {/* Botón de submit */}
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600"
          >
            {isSubmitting ? "Creando loteria..." : "Crear Lotería"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateLotteryPage;
