import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Card from "../components/card";
import {
  IDrawnTokenEvent,
  IEarlyPayWinnerPlayer,
  IPlayerGame,
  IPopUp,
  ISerialWinnerPlayer,
} from "../common/interfaces";
import DrawerVisor from "../components/player/drawer_visor";
import Loading from "../components/loading";
import {
  extractTokenImageFromSprite,
  fetchFromServer,
  preloadTokensSprite,
  speak,
} from "../common/functions";
import { Helmet } from "react-helmet";
import SerialWinnerAnimation from "../components/serial-winner-animation";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import MobileButtons from "../components/mobile_buttons";
import LotteryInfoColumn from "../components/lottery-info.column";
import WinnersAnimation from "../components/card-winners-animation";
import CardWinnerAnimation from "../components/player/card-winner-animation";
import PlayerRightColumn from "../components/player/player-right-column";
import { useSocket } from "../common/contexts/useSocket";
import EarlyPayWinnerAnimation from "../components/earlypay-winner-animation";
import PopUp from "../components/popup";
import { allTokens } from "../common/consts";
import { useTokenImageCache } from "../common/contexts/useTokenImageCache";

const PlayerPage: React.FC = () => {
  const { userId: playerId } = useOutletContext<{ userId: number }>();
  const { lotteryId } = useParams();

  const [playerGame, setPlayerGame] = useState<IPlayerGame>();

  const [isLeftColumnVisible, setIsLeftColumnVisible] = useState(false);
  const [isRightColumnVisible, setIsRightColumnVisible] = useState(false);

  const [popUp, setPopUp] = useState<IPopUp>();

  const [isCardWinner, setIsCardWinner] = useState<boolean>();

  const [loadingText, setLoadingText] = useState<string>();

  const [serialWinnerJustDrawn, setSerialWinnerJustDrawn] =
    useState<boolean>(false);

  const [earlyPayWinnerJustDrawn, setEarlyPayWinnerJustDrawn] =
    useState<boolean>(false);

  const [thereAreUnseenMessages, setThereAreUnseenMessages] = useState(false);

  const [drawnTokenEvent, setDrawnTokenEvent] = useState<IDrawnTokenEvent>();

  const hasMounted = useRef(false);

  const { socket } = useSocket();

  const cache = useTokenImageCache();

  const navigate = useNavigate();

  const currentRound = useMemo(
    () =>
      playerGame?.lottery.rounds.find(
        (round) => round.id === playerGame?.lottery.currentRoundId,
      ),
    [playerGame],
  );

  const fetchGame = useCallback(async () => {
    const response = await fetchFromServer(
      "GET",
      `/lotteries/mygame`,
      undefined,
      "player",
      navigate,
    );

    if (!response.ok) throw new Error();

    const playerGame: IPlayerGame = await response.json();

    return playerGame;
  }, [navigate]);

  const openLeftColumn = useCallback(() => {
    setIsLeftColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  }, []);

  const openRightColumn = useCallback(() => {
    setIsRightColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  }, []);

  const onStart = useCallback(async (data: any) => {
    const { startDate } = data;

    setPlayerGame((prevPlayerLottery) => {
      if (prevPlayerLottery) {
        const newCurrentRound = prevPlayerLottery.lottery.rounds.find(
          (round) => round.victories.length === 0,
        );

        return {
          ...prevPlayerLottery,
          lottery: {
            ...prevPlayerLottery.lottery,
            startDate: startDate,
            currentRoundId: newCurrentRound?.id,
          },
        };
      }

      return prevPlayerLottery; // En caso de que sea undefined, devolvemos el estado previo
    });
    speak("La lotería ha comenzado.");
  }, []);

  const onTokenDrawn = useCallback(
    async (drawnTokenEvent: IDrawnTokenEvent) => {
      setDrawnTokenEvent(drawnTokenEvent);
    },
    [],
  );

  const onSerialWinner = useCallback(
    async (serialWinnerPlayer: ISerialWinnerPlayer) => {
      // Actualizamos el ganador de Serial Ganador.
      setPlayerGame((prevPlayerGame) => {
        if (prevPlayerGame) {
          setSerialWinnerJustDrawn(true);
          return {
            ...prevPlayerGame,
            lottery: {
              ...prevPlayerGame.lottery,
              cardSerialWinner: serialWinnerPlayer,
            },
          };
        }
      });
    },
    [],
  );

  const onEarlyPayWinner = useCallback(
    async (earlyPayWinnerPlayer: IEarlyPayWinnerPlayer) => {
      // Actualizamos el ganador de Serial Ganador.
      setPlayerGame((prevPlayerGame) => {
        if (prevPlayerGame) {
          setEarlyPayWinnerJustDrawn(true);
          return {
            ...prevPlayerGame,
            lottery: {
              ...prevPlayerGame.lottery,
              earlyPayDrawWinner: earlyPayWinnerPlayer,
            },
          };
        }
      });
    },
    [],
  );

  const onPopUp = useCallback((popup: IPopUp) => {
    setPopUp(popup);
  }, []);

  const mountGame = useCallback(async () => {
    setLoadingText(`Descargando imágenes de las fichas...`);
    await preloadTokensSprite();

    setLoadingText(`Procesando imágenes de las fichas...`);
    await Promise.all(
      allTokens.map((token) => extractTokenImageFromSprite(token.name, cache)),
    );

    setLoadingText(`Cargando sus cartones y la información de la lotería...`);
    const _playerGame = await fetchGame();

    setPlayerGame(_playerGame);

    if (_playerGame.lottery.live) {
      if (!socket.hasListeners("start")) {
        socket.on("start", onStart);
      }

      if (!socket.hasListeners("serialWinner")) {
        socket.on("serialWinner", onSerialWinner);
      }

      if (!socket.hasListeners("earlyPayWinner")) {
        socket.on("earlyPayWinner", onEarlyPayWinner);
      }

      if (!socket.hasListeners("tokenDrawn")) {
        socket.on("tokenDrawn", onTokenDrawn);
      }

      if (!socket.hasListeners("popUp")) {
        socket.on("popUp", onPopUp);
      }

      socket.auth = {
        accessToken: localStorage.getItem("player_accessToken"),
      };
      socket.connect();
    }
  }, [
    socket,
    cache,
    fetchGame,
    onEarlyPayWinner,
    onPopUp,
    onSerialWinner,
    onStart,
    onTokenDrawn,
  ]);

  const handleLogout = useCallback(async () => {
    try {
      const response = await fetchFromServer(
        "POST",
        `/auth/player/logout`,
        undefined,
        "player",
        navigate,
      );

      if (response.ok) {
        navigate(`/loteria/${lotteryId}`);
        localStorage.removeItem("player_accessToken");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }, [lotteryId, navigate]);

  const nextRound = useCallback(() => {
    // Actualizamos las victorias en el round y el currentRoundId.
    setPlayerGame((prevPlayerGame) => {
      if (prevPlayerGame) {
        const rounds = prevPlayerGame.lottery.rounds;

        const currentIndex = rounds.findIndex(
          (round) => round.id === currentRound?.id,
        );

        const newCurrentRoundId =
          currentIndex !== -1 && currentIndex < rounds.length - 1
            ? rounds[currentIndex + 1].id
            : undefined;

        const newCurrentRound = prevPlayerGame.lottery.rounds.find(
          (round) => round.id === newCurrentRoundId,
        );

        if (newCurrentRoundId) {
          speak(
            `Todas las fichas han sido recogidas. Continuamos a la próxima ronda. Combinación ganadora: ${newCurrentRound?.winningCombination.name}, Premio: ${newCurrentRound?.award}`,
          );
        }

        return {
          ...prevPlayerGame,
          lottery: {
            ...prevPlayerGame.lottery,
            currentRoundId: newCurrentRoundId,
          },
        };
      }
    });

    setDrawnTokenEvent(undefined);
  }, [currentRound]);

  useEffect(() => {
    if (hasMounted.current) return;
    hasMounted.current = true;
    mountGame();

    return () => {
      socket.disconnect();

      if (socket.hasListeners("start")) {
        socket.off("start", onStart);
      }

      if (socket.hasListeners("serialWinner")) {
        socket.off("serialWinner", onSerialWinner);
      }

      if (socket.hasListeners("earlyPayWinner")) {
        socket.off("earlyPayWinner", onEarlyPayWinner);
      }

      if (socket.hasListeners("tokenDrawn")) {
        socket.off("tokenDrawn", onTokenDrawn);
      }

      if (socket.hasListeners("popUp")) {
        socket.off("popUp", onPopUp);
      }
    };
  }, [
    socket,
    fetchGame,
    mountGame,
    onEarlyPayWinner,
    onPopUp,
    onSerialWinner,
    onStart,
    onTokenDrawn,
    navigate,
  ]);

  useEffect(() => {
    // Agregar una entrada inicial al historial
    window.history.pushState(null, "", window.location.href);

    const handlePopState = () => {
      // Si no hay columnas abiertas, rehacer el estado actual
      if (!isLeftColumnVisible && !isRightColumnVisible) {
        window.history.pushState(null, "", window.location.href);
        return;
      }

      // Si hay una columna abierta, replegarla
      if (isLeftColumnVisible) {
        setIsLeftColumnVisible(false);
      } else if (isRightColumnVisible) {
        setIsRightColumnVisible(false);
      }
    };

    // Escucha el evento de retroceso del historial
    window.addEventListener("popstate", handlePopState);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isLeftColumnVisible, isRightColumnVisible]);

  if (!playerGame) {
    return <Loading text={loadingText} />;
  }

  return (
    <div className="flex flex-col h-dvh">
      <Helmet>
        <title>
          Lotería {playerGame.lottery.name} - Impulsado por Guacamayalote.online
        </title>
      </Helmet>
      {serialWinnerJustDrawn && (
        <SerialWinnerAnimation
          playerId={playerId}
          serialWinnerPlayer={playerGame.lottery.cardSerialWinner}
        />
      )}
      {earlyPayWinnerJustDrawn && (
        <EarlyPayWinnerAnimation
          playerId={playerId}
          earlyPayWinnerPlayer={playerGame.lottery.earlyPayDrawWinner}
          earlyPayAward={playerGame.lottery.earlyPayDrawAward}
        />
      )}
      {isCardWinner && (
        <CardWinnerAnimation
          lastDrawnTokenEvent={drawnTokenEvent!}
          currentRound={currentRound!}
          nextRound={nextRound}
          setIsCardWinner={setIsCardWinner}
        />
      )}
      {isCardWinner === false && (
        <WinnersAnimation
          lastDrawnTokenEvent={drawnTokenEvent!}
          currentRound={currentRound!}
          nextRound={nextRound}
          setIsCardWinner={setIsCardWinner}
        />
      )}
      {popUp && (
        <PopUp type={popUp.type} data={popUp.data} setPopUp={setPopUp} />
      )}
      {/* Botones flotantes para móviles */}
      <MobileButtons
        openLeftColumn={openLeftColumn}
        openRightColumn={openRightColumn}
        isOrganizer={false}
        thereAreUnseenMessages={thereAreUnseenMessages}
        className={`xl:hidden fixed bottom-4 right-4 z-30 flex flex-col space-y-2 transition-opacity duration-300 ${
          !isLeftColumnVisible && !isRightColumnVisible
            ? "opacity-100 visible"
            : "opacity-0 invisible"
        }`}
      />

      {/* Estructura de columnas */}
      <div className="flex flex-1 flex-row">
        {/* Columna izquierda */}
        <LotteryInfoColumn
          lottery={playerGame.lottery}
          currentRound={currentRound}
          isVisible={isLeftColumnVisible}
          setIsVisible={setIsLeftColumnVisible}
        />

        {/* Contenido central */}
        <div
          className="w-full relative xl:w-1/2 xl:ml-[25%]"
          style={{
            background: "linear-gradient(to right, #33FCFF, #5CADFF)",
          }}
        >
          {/* Fila superior fija dentro de la columna central */}
          <div
            className="sticky top-0 w-full text-white p-2 mb-4 z-10"
            style={{
              background: "linear-gradient(to right, #00CED1, #1E90FF)", // Gradiente
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="flex p-1 justify-between items-center">
              <div>
                <span>{playerGame.player.name}</span>{" "}
                <span className="text-sm">{`(${playerGame.player.id})`}</span>
              </div>

              {playerGame.player.paid ? (
                <span className="text-sm text-green-300">Pago verificado</span>
              ) : (
                <span className="text-sm text-red-500">No ha pagado</span>
              )}

              <button
                onClick={handleLogout}
                className="text-red-500 hover:text-red-700 font-bold"
              >
                Salir
              </button>
            </div>
            <DrawerVisor
              playerId={playerId}
              setPlayerGame={setPlayerGame}
              allTokens={allTokens}
              drawnTokenEvent={drawnTokenEvent}
              currentRound={currentRound}
              setIsCardWinner={setIsCardWinner}
            />
          </div>

          {/* Contenido principal */}
          <div className="my-4 flex justify-center items-center flex-wrap gap-4">
            {playerGame.myCards.map((card) => (
              <Card
                key={card.serial}
                card={card}
                winningCombination={
                  currentRound?.winningCombination.combination
                }
                drawnTokens={currentRound?.drawnTokens}
                showSerial={true}
              />
            ))}
          </div>
        </div>

        {/* Columna derecha */}
        <PlayerRightColumn
          isVisible={isRightColumnVisible}
          setIsVisible={setIsRightColumnVisible}
          setThereAreUnseenMessages={setThereAreUnseenMessages}
        />
      </div>
    </div>
  );
};

export default PlayerPage;
