import React, { useEffect, useRef, useState } from "react";
import { extractTokenImageFromSprite } from "../common/functions";
import markAnimation from "../lottie-animations/marker.json";
import Lottie from "lottie-react";
import { useTokenImageCache } from "../common/contexts/useTokenImageCache";

interface MyComponentProps {
  name: string;
  checked?: boolean;
  illuminated?: boolean;
  className?: string;
}

const Token: React.FC<MyComponentProps> = ({
  name,
  checked,
  illuminated,
  className,
}) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const cache = useTokenImageCache();
  const lastNameRef = useRef(name); // Mantener referencia del último `name`

  useEffect(() => {
    lastNameRef.current = name;
    extractTokenImageFromSprite(name, cache).then((imageData: string) => {
      if (lastNameRef.current === name) {
        setImageSrc(imageData);
      } else {
        console.log("Imagen desactualizada");
      }
    });
  }, [name, cache]);

  if (!imageSrc) return <div className="text-sm">Cargando</div>;

  return (
    <div
      className={`relative flex flex-col items-center justify-center p-2 ${
        illuminated ? "bg-white" : "bg-gray-300"
      }`}
    >
      {/* Imagen del token */}
      <img
        src={imageSrc}
        key={name}
        alt={name}
        className={className}
        onDragStart={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
      />

      {/* Nombre del token */}
      <div className="w-full text-center text-black text-xs overflow-hidden text-ellipsis whitespace-nowrap">
        {name}
      </div>

      {/* Marcar ficha si existe drawnDate */}
      {checked && (
        <Lottie
          animationData={markAnimation}
          loop={false}
          className="absolute w-full"
        />
      )}
    </div>
  );
};

export default Token;
