import React, { useState } from "react";
import { ILottery, ILotteryCardPlayer } from "../../common/interfaces";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

interface MyComponentProps {
  lottery: ILottery;
}
const data = [
  { name: "Jan", uv: 400, pv: 2400 },
  { name: "Feb", uv: 300, pv: 2210 },
  { name: "Mar", uv: 200, pv: 2290 },
];
const Statistics: React.FC<MyComponentProps> = ({ lottery }) => {
  return (
    <div className="mb-4">
      <LineChart width={400} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
      </LineChart>
    </div>
  );
};

export default Statistics;
