import React, { useEffect, useState } from "react";
import { ISerialWinnerPlayer } from "../common/interfaces";
import Card from "./card";
import { speak } from "../common/functions";

interface MyComponentProps {
  playerId?: number;
  serialWinnerPlayer: ISerialWinnerPlayer;
}

const SerialWinnerAnimation: React.FC<MyComponentProps> = ({
  playerId,
  serialWinnerPlayer,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      if (serialWinnerPlayer.id === playerId) {
        speak(
          `Felicidades, tienes el serial ganador ${serialWinnerPlayer.card.serial}`,
        );
      } else {
        speak(
          `${serialWinnerPlayer.name} tiene el serial ganador ${serialWinnerPlayer.card.serial}`,
        );
      }
    }
  }, [isVisible, serialWinnerPlayer, playerId]);

  if (!isVisible) return null;

  return (
    <div
      className="fixed flex overflow-y-auto flex-col items-center justify-center bg-black z-50"
      style={{ height: "100vh", width: "100vw" }}
    >
      {/* Botón de cerrar */}
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Cerrar
      </button>

      {/* Animación Lottie */}

      {/* Texto centrado arriba de la animación */}
      <div className="text-center mt-4 px-4">
        <h1 className="text-white text-2xl md:text-4xl font-bold">
          {serialWinnerPlayer.id === playerId && (
            <span className="text-yellow-500">¡Tienes el Serial Ganador!</span>
          )}
        </h1>
        {serialWinnerPlayer.id !== playerId && (
          <p className="bg-white text-black mt-2 px-2 py-1 rounded-md">
            {serialWinnerPlayer.name} tiene el serial ganador
          </p>
        )}
        <h2 className="text-white text-xl md:text-3xl font-bold p-2 animate-growShrink">
          {serialWinnerPlayer.card.serial}
        </h2>
      </div>
      <Card card={serialWinnerPlayer.card} showSerial={false} />
      {serialWinnerPlayer.id === playerId && (
        <p className="mt-2 text-white text-sm md:text-lg px-4">
          La Guacamaya te llamará pronto...
        </p>
      )}
    </div>
  );
};

export default SerialWinnerAnimation;
