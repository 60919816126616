import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../lottie-animations/loading.json";
interface MyComponentProps {
  text?: string;
}

const Loading: React.FC<MyComponentProps> = ({ text = "Cargando..." }) => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <Lottie
        animationData={loadingAnimation}
        loop={true}
        className="w-52 object-contain"
      />
      <div className="w-50%">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Loading;
