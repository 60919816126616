import React, { useState, useEffect } from "react";
import {
  IDrawnTokenEvent,
  ILottery,
  IRound,
  IToken,
} from "../../common/interfaces";
import { speak } from "../../common/functions";
import fireAnimation from "../../lottie-animations/hot.json";
import Lottie from "lottie-react";
import { useSocket } from "../../common/contexts/useSocket";
import { HiSpeakerphone } from "react-icons/hi";
import PopUpSelector from "./popup-selector";
import Token from "../token";

interface MyComponentProps {
  lottery: ILottery;
  allTokens: IToken[];
  setLottery: React.Dispatch<React.SetStateAction<ILottery | undefined>>;
  drawnTokenEvent?: IDrawnTokenEvent;
  setAreCardWinners: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  currentRound?: IRound;
}

const Drawer: React.FC<MyComponentProps> = ({
  lottery,
  allTokens,
  drawnTokenEvent,
  currentRound,
  setLottery,
  setAreCardWinners,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [isPopUpSelectorOpen, setIsPopUpSelectorOpen] = useState(false);
  const [visibleToken, setVisibleToken] = useState<IToken>();
  const { socket, connectionStatus } = useSocket();

  const speech = (drawnTokenEvent: IDrawnTokenEvent) => {
    if ("speechSynthesis" in window) {
      const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

      if (lastDrawnToken) {
        var speech = lastDrawnToken.name;

        if (drawnTokenEvent.winners.length > 0) {
          speech += ", y han cantado lotería. ";

          if (drawnTokenEvent.winners.length === 1) {
            speech +=
              drawnTokenEvent.winners[0].name +
              " ha ganado con el cartón número " +
              drawnTokenEvent.winners[0].cards[0].serial +
              ". ";
          } else if (drawnTokenEvent.winners.length > 1) {
            speech += "Hay " + drawnTokenEvent.winners.length + " ganadores. ";

            for (var i = 0; i < drawnTokenEvent.winners.length - 1; i++) {
              speech +=
                drawnTokenEvent.winners[i].name +
                " ha ganado con el cartón número " +
                drawnTokenEvent.winners[i].cards[0].serial +
                ", ";
            }

            speech +=
              "y " +
              drawnTokenEvent.winners.at(-1)?.name +
              " ha ganado con el cartón número " +
              drawnTokenEvent.winners.at(-1)?.cards[0].serial +
              ". ";
          }

          if (drawnTokenEvent.endDate) {
            speech += "La lotería ha terminado.";
          }
        }
        speak(speech);
      }
    }
  };

  useEffect(() => {
    let startFakeShufflerInterval: NodeJS.Timeout;
    let stopFakeShufflerTimeout: NodeJS.Timeout;

    if (drawnTokenEvent) {
      const notDrawnTokens = allTokens.filter(
        (token) =>
          !currentRound?.drawnTokens.some(
            (drawnToken) => drawnToken.id === token.id,
          ),
      );

      startFakeShufflerInterval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * notDrawnTokens.length);
        setVisibleToken(notDrawnTokens[randomIndex]);
      }, 100);

      stopFakeShufflerTimeout = setTimeout(() => {
        // Actualizamos los drawnTokens del round y los winners.
        setLottery((prevLottery) => {
          if (prevLottery) {
            const updatedRounds = prevLottery.rounds.map((round) =>
              round.id === drawnTokenEvent.roundId
                ? {
                    ...round,
                    drawnTokens: drawnTokenEvent.drawnTokens,
                    victories: drawnTokenEvent.winners,
                  }
                : round,
            );

            if (drawnTokenEvent.winners.length > 0) {
              setAreCardWinners(true);
            }

            return {
              ...prevLottery,
              rounds: updatedRounds,
              endDate: drawnTokenEvent.endDate
                ? drawnTokenEvent.endDate
                : prevLottery.endDate,
            };
          }
        });

        clearInterval(startFakeShufflerInterval);
        setVisibleToken(drawnTokenEvent?.drawnTokens.at(-1));
        speech(drawnTokenEvent!);
      }, 2000);
    }

    return () => {
      if (startFakeShufflerInterval) clearInterval(startFakeShufflerInterval);
      if (stopFakeShufflerTimeout) clearTimeout(stopFakeShufflerTimeout);
    };
  }, [drawnTokenEvent, allTokens, setAreCardWinners, setLottery]);

  useEffect(() => {
    if (isDrawing) {
      socket.emit("startDrawProcess");
    } else {
      socket.emit("stopDrawProcess");
    }
  }, [isDrawing, socket]);

  return (
    <div className="flex flex-col">
      {isPopUpSelectorOpen && (
        <PopUpSelector setIsPopUpSelectorOpen={setIsPopUpSelectorOpen} />
      )}
      {/* Fila superior */}
      <div className="flex w-full">
        {/* Columna 1 */}
        <div className="w-1/2 flex flex-col items-center justify-center">
          <img
            src="/bag.png"
            alt="Bolsa de fichas"
            onClick={() => {}}
            className={`w-32 object-contain ${
              isDrawing &&
              lottery.startDate &&
              !lottery.endDate &&
              connectionStatus === "connected"
                ? "animate-growShrink cursor-grab"
                : ""
            }`}
          />
          {lottery.startDate &&
            !lottery.endDate &&
            connectionStatus === "connected" && (
              <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="auto-switch"
                  checked={isDrawing}
                  onChange={(e) => {
                    setIsDrawing(e.target.checked);
                  }}
                  className="w-5 h-5 cursor-pointer accent-blue-500"
                />
                <label
                  htmlFor="auto-switch"
                  className="text-sm text-white cursor-pointer"
                >
                  Automático
                </label>
                <button
                  className="w-12 h-12 bg-white text-turquoise-900 rounded-full shadow-lg flex items-center justify-center"
                  onClick={() => setIsPopUpSelectorOpen(true)}
                >
                  <HiSpeakerphone className="w-6 h-6" />
                </button>
              </div>
            )}
        </div>

        {/* Columna 2 */}
        <div className="w-1/2 flex flex-col items-center justify-center border-red-700 border-2 rounded-lg bg-white p-2">
          {currentRound && (
            <div className="text-black text-sm">
              Van {currentRound.drawnTokens.length || 0} de {allTokens.length}{" "}
              fichas
            </div>
          )}
          {visibleToken && (
            <Token
              name={visibleToken?.name}
              illuminated={true}
              className="w-20 object-contain"
            />
          )}
        </div>
      </div>

      {/* Fila inferior */}
      <div className="relative flex mt-1 whitespace-nowrap">
        {drawnTokenEvent?.hots &&
          drawnTokenEvent?.hots.length > 0 &&
          `${drawnTokenEvent?.hots.length} |`}
        <div className="flex overflow-x-scroll ml-1">
          {/* Jugadores Esperando */}
          {drawnTokenEvent?.hots?.map((hot, index) => (
            <span
              key={index}
              className="flex items-center justify-center mx-2 text-sm"
            >
              <Lottie
                animationData={fireAnimation}
                loop={true}
                initialSegment={[0, 25]}
                className="w-4 mr-1"
              />
              {hot.playerName}({hot.cardId})
            </span>
          ))}
        </div>
      </div>

      <div className="flex flex-row justify-center gap-1">
        {lottery.startDate &&
          lottery.cardSerialDraw &&
          !lottery.cardSerialWinner &&
          connectionStatus === "connected" && (
            <div className="flex justify-center mt-4">
              <button
                className="px-6 py-2 text-white font-bold rounded"
                style={{ backgroundColor: "#00CED1" }}
                onClick={(e) => {
                  (e.target as HTMLButtonElement).style.display = "none";
                  socket.emit("drawserial");
                }}
              >
                Sortear Serial
              </button>
            </div>
          )}

        {lottery.startDate &&
          lottery.earlyPayDraw &&
          !lottery.earlyPayDrawWinner &&
          connectionStatus === "connected" && (
            <div className="flex justify-center mt-4">
              <button
                className="px-6 py-2 text-white font-bold rounded"
                style={{ backgroundColor: "#00CED1" }}
                onClick={(e) => {
                  (e.target as HTMLButtonElement).style.display = "none";
                  socket.emit("drawearlypay");
                }}
              >
                Sortear Pronto Pago
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default Drawer;
