import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IDrawnTokenEvent,
  IEarlyPayWinnerPlayer,
  ILottery,
  ISerialWinnerPlayer,
} from "../common/interfaces";
import SerialWinnerAnimation from "../components/serial-winner-animation";
import Loading from "../components/loading";
import {
  extractTokenImageFromSprite,
  fetchFromServer,
  preloadTokensSprite,
  speak,
} from "../common/functions";
import { useNavigate, useParams } from "react-router-dom";
import MobileButtons from "../components/mobile_buttons";
import LotteryInfoColumn from "../components/lottery-info.column";
import Drawer from "../components/organizer/drawer";
import CardWinnersAnimation from "../components/card-winners-animation";
import ChatWindow from "../components/chat_window";
import OrganizerRightColumn from "../components/organizer/organizer-right-column";
import { useSocket } from "../common/contexts/useSocket";
import { Helmet } from "react-helmet";
import EarlyPayWinnerAnimation from "../components/earlypay-winner-animation";
import { useTokenImageCache } from "../common/contexts/useTokenImageCache";
import { allTokens } from "../common/consts";

const StagePage: React.FC = () => {
  const { lotteryId } = useParams();
  const [isLeftColumnVisible, setisLeftColumnVisible] = useState(false);
  const [isRightColumnVisible, setisRightColumnVisible] = useState(false);
  const [lottery, setLottery] = useState<ILottery>();
  const [areCardWinners, setAreCardWinners] = useState<boolean>();
  const [loadingText, setLoadingText] = useState<string>();
  const [serialWinnerJustDrawn, setSerialWinnerJustDrawn] =
    useState<boolean>(false);
  const [earlyPayWinnerJustDrawn, setEarlyPayWinnerJustDrawn] =
    useState<boolean>(false);
  const { socket } = useSocket();
  const [drawnTokenEvent, setDrawnTokenEvent] = useState<IDrawnTokenEvent>();

  const currentRound = useMemo(
    () => lottery?.rounds.find((round) => round.id === lottery?.currentRoundId),
    [lottery],
  );

  const cache = useTokenImageCache();
  const navigate = useNavigate();

  const fetchLottery = useCallback(async () => {
    const response = await fetchFromServer("GET", `/lotteries/${lotteryId}`);

    if (!response.ok) {
      navigate("/", { replace: true });
    } else {
      const lottery: ILottery = await response.json();
      return lottery;
    }
  }, [lotteryId, navigate]);

  const onStart = useCallback(async (data: any) => {
    const { startDate } = data;

    setLottery((prevLottery) => {
      if (prevLottery) {
        const newCurrentRound = prevLottery.rounds.find(
          (round) => round.victories.length === 0,
        );

        if (prevLottery) {
          return {
            ...prevLottery,
            startDate: startDate,
            currentRoundId: newCurrentRound?.id,
          };
        }
      }

      return prevLottery;
    });
    speak("La lotería ha comenzado.");
  }, []);

  const onTokenDrawn = useCallback(
    async (drawnTokenEvent: IDrawnTokenEvent) => {
      setDrawnTokenEvent(drawnTokenEvent);
    },
    [],
  );

  const onSerialWinner = useCallback(
    async (serialWinnerPlayer: ISerialWinnerPlayer) => {
      // Actualizamos el ganador de Serial Ganador.
      setLottery((prevLottery) => {
        if (prevLottery) {
          setSerialWinnerJustDrawn(true);
          return {
            ...prevLottery,
            cardSerialWinner: serialWinnerPlayer,
          };
        }
      });
    },
    [],
  );

  const onEarlyPayWinner = useCallback(
    async (earlyPayWinnerPlayer: IEarlyPayWinnerPlayer) => {
      // Actualizamos el ganador de Serial Ganador.
      setLottery((prevLottery) => {
        if (prevLottery) {
          setEarlyPayWinnerJustDrawn(true);
          return {
            ...prevLottery,
            earlyPayDrawWinner: earlyPayWinnerPlayer,
          };
        }
      });
    },
    [],
  );

  const onError = useCallback(async (message: string) => {
    alert(message);
  }, []);

  const mountGame = useCallback(async () => {
    setLoadingText(`Descargando imágenes de las fichas...`);
    await preloadTokensSprite();

    setLoadingText(`Procesando imágenes de las fichas...`);
    await Promise.all(
      allTokens.map((token) => extractTokenImageFromSprite(token.name, cache)),
    );

    setLoadingText(`Cargando la información de la lotería...`);
    const _lottery = await fetchLottery();
    if (_lottery) {
      setLottery(_lottery);

      if (_lottery.live) {
        if (!socket.hasListeners("start")) {
          socket.on("start", onStart);
        }

        if (!socket.hasListeners("serialWinner")) {
          socket.on("serialWinner", onSerialWinner);
        }

        if (!socket.hasListeners("earlyPayWinner")) {
          socket.on("earlyPayWinner", onEarlyPayWinner);
        }

        if (!socket.hasListeners("tokenDrawn")) {
          socket.on("tokenDrawn", onTokenDrawn);
        }

        if (!socket.hasListeners("error")) {
          socket.on("error", onError);
        }

        socket.io.opts.query = { lotteryId: _lottery.id };
        socket.auth = {
          accessToken: localStorage.getItem("organizer_accessToken"),
        };
        socket.connect();
      }
    }
  }, [
    socket,
    cache,
    fetchLottery,
    onEarlyPayWinner,
    onError,
    onSerialWinner,
    onStart,
    onTokenDrawn,
  ]);

  const openLeftColumn = useCallback(() => {
    setisLeftColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  }, []);

  const openRightColumn = useCallback(() => {
    setisRightColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  }, []);

  const nextRound = useCallback(() => {
    // Actualizamos las victorias en el round y el currentRoundId.
    setLottery((prevLottery) => {
      if (prevLottery) {
        const rounds = prevLottery.rounds!;

        const currentIndex = rounds.findIndex(
          (round) => round.id === currentRound?.id,
        );

        const newCurrentRoundId =
          currentIndex !== -1 && currentIndex < rounds.length - 1
            ? rounds[currentIndex + 1].id
            : undefined;

        return {
          ...prevLottery,
          currentRoundId: newCurrentRoundId,
        };
      }
    });

    setDrawnTokenEvent(undefined);
  }, [currentRound]);

  useEffect(() => {
    mountGame();

    return () => {
      socket.disconnect();

      if (socket.hasListeners("start")) {
        socket.off("start", onStart);
      }

      if (socket.hasListeners("serialWinner")) {
        socket.off("serialWinner", onSerialWinner);
      }

      if (socket.hasListeners("earlyPayWinner")) {
        socket.off("earlyPayWinner", onEarlyPayWinner);
      }

      if (socket.hasListeners("tokenDrawn")) {
        socket.off("tokenDrawn", onTokenDrawn);
      }

      if (socket.hasListeners("error")) {
        socket.off("error", onError);
      }
    };
  }, [
    lotteryId,
    socket,
    mountGame,
    onEarlyPayWinner,
    onError,
    onSerialWinner,
    onStart,
    onTokenDrawn,
    navigate,
  ]);

  useEffect(() => {
    // Agregar una entrada inicial al historial
    window.history.pushState(null, "", window.location.href);

    const handlePopState = () => {
      // Si no hay columnas abiertas, rehacer el estado actual
      if (!isLeftColumnVisible && !isRightColumnVisible) {
        window.history.pushState(null, "", window.location.href);
        return;
      }

      // Si hay una columna abierta, replegarla
      if (isLeftColumnVisible) {
        setisLeftColumnVisible(false);
      } else if (isRightColumnVisible) {
        setisRightColumnVisible(false);
      }
    };

    // Escucha el evento de retroceso del historial
    window.addEventListener("popstate", handlePopState);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isLeftColumnVisible, isRightColumnVisible]);

  if (!lottery) {
    return <Loading text={loadingText} />;
  }

  return (
    <div className="flex flex-col h-dvh">
      <Helmet>
        <title>
          Tarima de Lotería {lottery.name} - Impulsado por Guacamayalote.online
        </title>
      </Helmet>
      {serialWinnerJustDrawn && (
        <SerialWinnerAnimation serialWinnerPlayer={lottery.cardSerialWinner} />
      )}
      {earlyPayWinnerJustDrawn && (
        <EarlyPayWinnerAnimation
          earlyPayWinnerPlayer={lottery.earlyPayDrawWinner}
          earlyPayAward={lottery.earlyPayDrawAward}
        />
      )}
      {areCardWinners && (
        <CardWinnersAnimation
          lastDrawnTokenEvent={drawnTokenEvent!}
          currentRound={currentRound!}
          nextRound={nextRound}
          setIsCardWinner={setAreCardWinners}
        />
      )}

      {/* Botones flotantes para móviles */}
      <MobileButtons
        openLeftColumn={openLeftColumn}
        openRightColumn={openRightColumn}
        isOrganizer={true}
        className={`xl:hidden fixed bottom-4 right-4 z-30 flex flex-col space-y-2 transition-opacity duration-300 ${
          !isLeftColumnVisible && !isRightColumnVisible
            ? "opacity-100 visible"
            : "opacity-0 invisible"
        }`}
      />

      {/* Estructura de columnas */}
      <div className="flex flex-1 flex-row min-h-0">
        {/* Columna izquierda */}
        <LotteryInfoColumn
          lottery={lottery}
          currentRound={currentRound}
          isVisible={isLeftColumnVisible}
          setIsVisible={setisLeftColumnVisible}
        />

        {/* Contenido central */}
        <div
          className="w-full relative xl:w-1/2 xl:ml-[25%] flex flex-col min-h-0"
          style={{
            background: "linear-gradient(to right, #33FCFF, #5CADFF)", // Gradiente
          }}
        >
          {/* Fila superior fija dentro de la columna central */}
          <div
            className="sticky top-0 w-full text-white p-2 mb-4 z-10"
            style={{
              background: "linear-gradient(to right, #00CED1, #1E90FF)", // Gradiente
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="flex p-1 justify-between items-center">
              <button
                onClick={() => navigate("/organizador/loterias")}
                className="text-green-200 hover:text-green-700 font-bold"
              >
                Atras
              </button>
              <span>{lottery.organizer.name}</span>
            </div>
            <Drawer
              lottery={lottery}
              setLottery={setLottery}
              allTokens={allTokens}
              currentRound={currentRound}
              drawnTokenEvent={drawnTokenEvent}
              setAreCardWinners={setAreCardWinners}
            />
            {!lottery.startDate && (
              <div className="flex justify-center mt-4">
                <button
                  className="px-6 py-2 text-white font-bold rounded"
                  style={{ backgroundColor: "#00CED1" }}
                  onClick={(e) => {
                    (e.target as HTMLButtonElement).style.display = "none";
                    socket.emit("start");
                  }}
                >
                  Empezar Lotería
                </button>
              </div>
            )}
          </div>

          {/* Contenido principal */}
          <div className="flex-1 flex justify-center items-center p-2 min-h-0">
            <ChatWindow isOrganizer={true} />
          </div>
        </div>

        {/* Columna derecha */}
        <OrganizerRightColumn
          isVisible={isRightColumnVisible}
          setIsVisible={setisRightColumnVisible}
          allTokens={allTokens}
          currentRound={currentRound!}
        />
      </div>
    </div>
  );
};

export default StagePage;
