import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchFromServer, speak } from "../common/functions";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const SellerLogin: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("seller_accessToken")) {
      navigate("/vendedor/loterias");
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setButtonDisabled(true);
    setErrors([]);
    const response = await fetchFromServer(
      "POST",
      `/auth/seller/login`,
      formData,
    );

    const result = await response.json();

    if (response.ok) {
      localStorage.setItem("seller_accessToken", result.accessToken);
      navigate("/vendedor/loterias", { replace: true });
    } else {
      if (Array.isArray(result.message)) {
        setErrors(result.message);
      } else {
        setErrors([result.message]);
      }
    }
    setButtonDisabled(false);
  };

  return (
    <div
      className="h-dvh"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        display: "flex",
        flexDirection: "column", // Apila los elementos verticalmente
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>Entrar como vendedor - Impulsado por Guacamayalote.online</title>
      </Helmet>
      {/* Div para las guacamayas */}
      <div className="w-1/2 flex items-center justify-center mb-4">
        <img
          src="/guacamayas.png"
          alt="Guacamayas"
          className="max-w-28 object-contain"
        />
      </div>

      {/* Formulario */}
      <div className="max-w-[90%] p-6 bg-white rounded-lg shadow-lg">
        {/* Mensaje de registro exitoso */}
        {searchParams.get("register") === "true" && (
          <div className="m-4 text-green-500 font-semibold text-center">
            Se ha registrado con éxito
          </div>
        )}

        <h1 className="text-2xl font-bold text-center mb-2">Iniciar Sesión</h1>
        <h2 className="text-2x1 font-bold text-center mb-2">Vendedor</h2>

        <form className="space-y-4">
          {/* Correo electrónico */}
          <div className="mb-4">
            <input
              id="email"
              type="email"
              name="email"
              autoComplete="username"
              placeholder="Correo electrónico"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Contraseña */}
          <div className="mb-6">
            <input
              id="password"
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder="Contraseña"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Error Messages */}
          {errors &&
            errors.map((error, index) => (
              <div
                key={index}
                className="mb-4 text-red-500 font-semibold text-center"
              >
                {error}
              </div>
            ))}
          {/* Botón de inicio de sesión */}
          <button
            type="button"
            onClick={() => {
              // Aprovechamos esta interaccion del usuario para poder tener permisos para
              // SpeechSynthesisUtterance. El cuerpo de la funcion tiene que ir directamente en el boton para que speak() funcione.
              speak("Bienvenido");
              handleSubmit();
            }}
            className="w-full py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={buttonDisabled}
          >
            Iniciar sesión
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SellerLogin;
