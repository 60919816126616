import React, { useState } from "react";
import { ILottery, ILotteryCardPlayer } from "../../common/interfaces";
import { useNavigate } from "react-router-dom";
import { fetchFromServer, handleSharePass } from "../../common/functions";

interface MyComponentProps {
  lottery: ILottery;
}

const SellerPlayers: React.FC<MyComponentProps> = ({ lottery }) => {
  const [player, setPlayer] = useState<ILotteryCardPlayer>();
  const [success, setSuccess] = useState<boolean>();
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputValue, setInputValue] = useState<string>("");

  const navigate = useNavigate();

  const fetchPlayers = async () => {
    if (searchTerm) {
      try {
        setIsLoading(true);
        setSuccess(false);
        setErrors([]);

        const response = await fetchFromServer(
          "GET",
          `/lotteries/seller/${lottery.id}/players?searchTerm=${searchTerm}`,
          null,
          "seller",
          navigate,
        );

        if (response.ok) {
          const player: ILotteryCardPlayer = await response.json();
          setPlayer(player);
        } else {
          setPlayer(undefined);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error buscando al jugador:", error);
      }
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const handleDelete = async (playerId: number) => {
    // Confirmar antes de eliminar
    if (
      window.confirm(
        "¿Estás seguro de que deseas eliminar este jugador? Los cartones que posee quedaran libres para la venta.",
      )
    ) {
      const deletePlayer = async () => {
        try {
          const response = await fetchFromServer(
            "DELETE",
            `/lotteries/seller/${lottery.id}/players/${playerId}`,
            undefined,
            "seller",
            navigate,
          );

          if (response.ok) {
            setPlayer(undefined);
          } else {
            const result = await response.json();

            const errors = Array.isArray(result.message)
              ? result.message
              : [result.message];

            setErrors(errors);
          }
        } catch (ex) {}
      };

      await deletePlayer();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, checked } = e.target;

    setPlayer((prevPlayer) => {
      if (prevPlayer) {
        const updatedPlayer = {
          ...prevPlayer,
          [name]: type === "checkbox" ? checked : value,
        };
        return updatedPlayer;
      }
    });

    setSuccess(false);
  };

  const handleAddCardId = (newCardId: string) => {
    if (newCardId) {
      const newId = parseInt(newCardId.trim(), 10);

      if (!isNaN(newId)) {
        setPlayer((prevPlayer) => {
          if (prevPlayer) {
            const updatedPlayer = { ...prevPlayer };

            if (!updatedPlayer.cardIds?.includes(newId)) {
              updatedPlayer.cardIds = [...updatedPlayer.cardIds!, newId];
            }
            return updatedPlayer;
          }
        });
        setInputValue("");
      }
    }
  };

  const handleRemoveCardId = (cardId: number) => {
    setPlayer((prevPlayer) => {
      if (prevPlayer) {
        const updatedPlayer = { ...prevPlayer };
        updatedPlayer.cardIds = updatedPlayer.cardIds?.filter(
          (id) => id !== cardId,
        );
        return updatedPlayer;
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = {
      player: {
        name: player?.name,
        email: player?.email,
        phone: player?.phone,
        paid: player?.paid,
        payment_reference: player?.payment_reference,
      },
      cardIds: player?.cardIds,
    };
    setErrors([]);
    setSuccess(false);
    try {
      const response = await fetchFromServer(
        "PUT",
        `/lotteries/seller/players/${player?.id}`,
        formData,
        "seller",
        navigate,
      );

      if (response.ok) {
        setSuccess(true);
      } else {
        const result = await response.json();

        const errors = Array.isArray(result.message)
          ? result.message
          : [result.message];

        setErrors(errors);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex space-x-4 my-6">
        <input
          type="text"
          id="searchTerm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-1 border border-gray-300 rounded px-3 py-2 shadow-sm"
          placeholder="Serial o Pase"
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              fetchPlayers();
            }
          }}
        />
        <button
          type="button"
          onClick={fetchPlayers}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 shadow-sm"
        >
          Buscar
        </button>
      </div>
      {/* Lista de jugadores */}
      <div className="space-y-6">
        {!isLoading && player && (
          <form
            key={player?.id}
            onSubmit={(e) => handleSubmit(e)}
            className="bg-white p-6 rounded-lg shadow-md space-y-6"
          >
            {/* Serial del Cartón */}
            <section className="space-y-4">
              {/* Botón de eliminar */}
              <button
                type="button"
                onClick={() => handleDelete(player?.id!)}
                className="right-6 px-2 bg-red-500 text-white rounded hover:bg-red-600 text-sm"
              >
                X
              </button>
              <label
                htmlFor="cartonIds"
                className="block text-sm font-medium text-gray-700"
              >
                Añadir Cartones
              </label>
              <div className="flex items-center space-x-4 my-6">
                <input
                  type="text"
                  id="cartonIds"
                  value={inputValue || ""}
                  onChange={(e) => handleInputChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddCardId(inputValue);
                    }
                  }}
                  maxLength={5}
                  className="flex-grow min-w-0 border border-gray-300 rounded px-3 py-2 shadow-sm"
                  placeholder="Serial del cartón"
                />
                <button
                  type="button"
                  onClick={() => handleAddCardId(inputValue)}
                  className="flex-shrink-0 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 shadow-sm"
                >
                  Añadir
                </button>
              </div>

              {/* Lista de seriales de cartones */}
              <div className="flex flex-wrap gap-2 mt-4">
                {player?.cardIds?.map((cardId) => (
                  <span
                    key={cardId}
                    className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full shadow-sm"
                  >
                    {cardId}
                    <button
                      type="button"
                      onClick={() => handleRemoveCardId(cardId)}
                      className="ml-2 bg-blue-200 hover:bg-blue-300 rounded-full w-5 h-5 flex items-center justify-center text-blue-800"
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </section>

            {/* Información del jugador */}
            <section className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  minLength={3}
                  value={player.name || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  placeholder="Nombre del jugador"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={player.email || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  placeholder="Correo electrónico"
                />
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Teléfono
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={player.phone || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  placeholder="Número de teléfono"
                />
              </div>

              <div>
                <label
                  htmlFor="paid"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pagado
                </label>
                <input
                  type="checkbox"
                  id="paid"
                  name="paid"
                  onChange={handleChange}
                  checked={player.paid}
                  className=""
                />
                {player.paid && (
                  <input
                    type="text"
                    id="payment_reference"
                    name="payment_reference"
                    placeholder="Referencia de pago"
                    value={player.payment_reference}
                    maxLength={100}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                  />
                )}
              </div>
            </section>

            {/* Pase y mensajes */}
            <section>
              <p className="text-sm text-gray-700">Pase: {player?.pass}</p>
              <button
                type="button"
                onClick={async () =>
                  await handleSharePass(lottery, player?.pass!)
                }
                className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 text-sm"
              >
                Compartir
              </button>
              {success && (
                <p className="text-green-500 text-sm mt-2">
                  Jugador actualizado exitosamente.
                </p>
              )}
              {errors.map((error, index) => (
                <p key={index} className="text-red-500 text-sm">
                  {error}
                </p>
              ))}
            </section>

            {/* Botón actualizar */}
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 shadow-md"
            >
              Actualizar
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SellerPlayers;
