import React, { useState, useEffect } from "react";
import { ICard, ILottery, ILotterySeller } from "../common/interfaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "../components/card";
import Loading from "../components/loading";
import {
  extractTokenImageFromSprite,
  fetchFromServer,
  handleShareShowcase,
  preloadTokensSprite,
} from "../common/functions";
import {
  FaCashRegister,
  FaInfoCircle,
  FaShareAlt,
  FaWhatsapp,
} from "react-icons/fa";
import LotteryInfoColumn from "../components/lottery-info.column";
import Paginator from "../components/paginator";
import { Helmet } from "react-helmet";
import { allTokens } from "../common/consts";
import { useTokenImageCache } from "../common/contexts/useTokenImageCache";

interface PaginatedResponse {
  seller: ILotterySeller;
  cards: ICard[];
  totalPages: number;
}

const ShowcasePage: React.FC = () => {
  const location = useLocation();
  const { lotteryId } = useParams();
  const [lottery, setLottery] = useState<ILottery>();
  const [seller, setSeller] = useState<ILotterySeller>();
  const [cards, setCards] = useState<ICard[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [selectedSerials, setSelectedSerials] = useState<number[]>([]);
  const [loadingText, setLoadingText] = useState<string>();
  const cache = useTokenImageCache();
  const navigate = useNavigate();
  const [showLotteryInfoColumn, setShowLotteryInfoColumn] = useState(false);

  const currentRound = lottery?.rounds.find(
    (round) => round.id === lottery?.currentRoundId,
  );

  useEffect(() => {
    const mountLottery = async () => {
      setLoadingText(`Descargando imágenes de las fichas...`);
      await preloadTokensSprite();

      setLoadingText(`Procesando imágenes de las fichas...`);
      await Promise.all(
        allTokens.map((token) =>
          extractTokenImageFromSprite(token.name, cache),
        ),
      );

      setLoadingText(`Cargando la información de la lotería...`);
      const response = await fetchFromServer("GET", `/lotteries/${lotteryId}`);

      if (response.ok) {
        const lottery: ILottery = await response.json();
        setLottery(lottery);
      } else {
        navigate("/");
      }
    };

    mountLottery();
  }, [lotteryId, cache, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fetchCards = async () => {
      setIsLoadingCards(true);
      try {
        const response = await fetchFromServer(
          "GET",
          `/lotteries/${lotteryId}/availableCards?sellerId=${queryParams.get("sellerId")}&page=${currentPage}`,
        );

        if (!response.ok) {
          throw new Error("Error en la solicitud de cartones disponibles.");
        }
        const data: PaginatedResponse = await response.json();
        setCards(data.cards);
        setSeller(data.seller);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching lotteries:", error);
      }
      setIsLoadingCards(false);
    };

    fetchCards();
  }, [lotteryId, currentPage, location]);

  const goSellCards = async () => {
    navigate(
      `/vendedor/loteria/${lottery?.id}/venderCartones?serials=` +
        selectedSerials.join(","),
    );
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedSerials(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((serial) => serial !== id) // Deselect
          : [...prevSelected, id], // Select
    );
  };

  if (!lottery) {
    return <Loading text={loadingText} />;
  }

  return (
    <div className="h-dvh flex flex-col md:flex-row">
      <Helmet>
        <title>
          Vitrina de Lotería {lottery.name} - Impulsado por Guacamayalote.online
        </title>
      </Helmet>
      {/* Columna Izquierda */}
      <LotteryInfoColumn
        lottery={lottery}
        currentRound={currentRound}
        isVisible={showLotteryInfoColumn}
        setIsVisible={setShowLotteryInfoColumn}
      />

      {/* Columna Derecha */}
      <div
        className="flex-1 bg-gray-100 w-full xl:w-3/4 xl:ml-[25%] overflow-y-auto"
        style={{
          background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        }}
      >
        <div className="p-6">
          {/* Cartones Disponibles */}
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-2xl font-bold text-dodgerBlue-500 mb-4">
              {!lottery.startDate
                ? "Cartones Disponibles"
                : "La lotería ya comenzó"}
            </h2>
            {lottery.startDate || lottery.paid ? (
              <div className="bg-red-500 text-white m-2 p-2 rounded-lg">
                Esta lotería ya cerró. Los siguientes cartones no fueron
                vendidos.
              </div>
            ) : (
              <div className="bg-white text-dodgerBlue-500 m-2 p-2 rounded-lg">
                Selecciona los cartones que más te gusten y contacta{" "}
                {seller
                  ? `al vendedor autorizado ${seller.name}`
                  : "a Loterías Guacamaya"}
                {" vía Whatsapp al "}
                <a
                  href={`https://wa.me/${seller ? seller.phone : lottery.organizer.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline inline-flex items-center gap-1"
                >
                  <FaWhatsapp className="text-green-500" />{" "}
                  {seller ? seller.phone : lottery.organizer.phone}
                </a>
              </div>
            )}
            <button
              type="button"
              className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
              onClick={() => navigate("entrar")}
            >
              Entrar
            </button>
            {cards.length === 0 && (
              <div className="w-full text-center mt-2">
                No hay cartones disponibles
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
              {cards.map((card, index) => (
                <div key={`card-${index}`} id={`card-${index}`}>
                  <label>
                    <input
                      type="checkbox"
                      className={
                        localStorage.getItem("seller_accessToken")
                          ? ""
                          : "hidden"
                      }
                      checked={selectedSerials.includes(card.serial)}
                      onChange={() => handleCheckboxChange(card.serial)}
                    />
                    <Card card={card} showSerial={true} />
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Paginación */}
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            isLoading={isLoadingCards}
          />
        </div>
      </div>
      <div
        className={`xl:hidden fixed bottom-4 right-4 z-30 flex flex-col space-y-2 transition-opacity duration-300 ${
          !showLotteryInfoColumn ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        {selectedSerials.length > 0 && (
          <button
            className="w-12 h-12 bg-turquoise-900 text-white rounded-full shadow-lg flex items-center justify-center"
            onClick={() => goSellCards()}
          >
            <FaCashRegister className="w-6 h-6" />
          </button>
        )}
        <button
          className="w-12 h-12 bg-turquoise-900 text-white rounded-full shadow-lg flex items-center justify-center"
          onClick={() => setShowLotteryInfoColumn(true)}
        >
          <FaInfoCircle className="w-6 h-6" />
        </button>

        <button
          className="w-12 h-12 text-turquoise-900 bg-white rounded-full shadow-lg flex items-center justify-center"
          onClick={() => handleShareShowcase(lottery, seller)}
        >
          <FaShareAlt className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default ShowcasePage;
