import React, { createContext, useContext, useRef } from "react";

const TokenImageCacheContext = createContext<Map<string, string> | null>(null);

export const TokenImageCacheProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const cacheRef = useRef(new Map<string, string>());

  return (
    <TokenImageCacheContext.Provider value={cacheRef.current}>
      {children}
    </TokenImageCacheContext.Provider>
  );
};

export const useTokenImageCache = () => {
  const cache = useContext(TokenImageCacheContext);
  if (!cache)
    throw new Error(
      "useTokenImageCache must be used within a TokenImageCacheProvider",
    );
  return cache;
};
