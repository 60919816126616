import React from "react";

interface MyComponentProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<MyComponentProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-2xl p-6 m-2 relative w-full">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-red-500 text-white px-2 py-1 rounded-lg z-10"
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
