import React, { useState, useEffect } from "react";
import { ILottery } from "../common/interfaces";
import { useNavigate, useOutletContext } from "react-router-dom";
import { fetchFromServer } from "../common/functions";
import { FaCashRegister, FaSearch, FaStore } from "react-icons/fa";
import Paginator from "../components/paginator";
import { Helmet } from "react-helmet";
const { DateTime } = require("luxon");

interface OutletContextType {
  userId: number;
  role: string;
}

interface PaginatedResponse {
  lotteries: ILottery[];
  totalPages: number;
}

const LotteriesSellerPage: React.FC = () => {
  const [lotteries, setLotteries] = useState<ILottery[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userId } = useOutletContext<OutletContextType>(); // Captura el contexto
  const [lotteryError, setLotteryError] = useState<{
    [lotteryId: number]: string;
  }>({});
  const navigate = useNavigate();

  const calculateLotteryState = (lottery: ILottery) => {
    if (!lottery.startDate) {
      return <span className="text-yellow-500">Cartones en Venta</span>;
    }

    if (lottery.startDate && !lottery.endDate) {
      return <span className="text-green-500">Ha comenzado</span>;
    }

    if (lottery.endDate) {
      return <span className="text-red-500">Ha terminado</span>;
    }

    return "Estado desconocido";
  };

  useEffect(() => {
    const fetchLotteries = async () => {
      try {
        const response = await fetchFromServer(
          "GET",
          `/lotteries/seller/lotteries?page=${currentPage}`,
          null,
          "seller",
          navigate,
        );

        if (response.ok) {
          const data: PaginatedResponse = await response.json();
          setLotteries(data.lotteries);
          setTotalPages(data.totalPages);
        }
      } catch (ex) {}
      setIsLoading(false);
    };

    fetchLotteries();
  }, [currentPage]);

  const handleLogout = async () => {
    try {
      const response = await fetchFromServer(
        "POST",
        `/auth/seller/logout`,
        undefined,
        "seller",
        navigate,
      );

      if (response.ok) {
        localStorage.removeItem("seller_accessToken");
        navigate("/vendedor/login");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className="min-h-dvh bg-gray-100"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
      }}
    >
      <Helmet>
        <title>Panel de Vendedor - Loterías Guacamaya</title>
      </Helmet>
      {/* Encabezado */}
      <header className="w-full bg-white shadow p-4 flex justify-between items-center fixed z-10">
        <h1 className="text-2xl font-bold">Mis Loterías</h1>
        <button
          onClick={handleLogout}
          className="text-red-500 hover:text-red-700 font-bold"
        >
          Salir
        </button>
      </header>

      {/* Lista de loterías */}
      <div className="pt-20 px-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {!isLoading ? (
          lotteries.map((lottery, index) => (
            <div
              key={lottery.id}
              className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between relative"
            >
              {/* Información de la lotería */}
              <div className="p-4 bg-white rounded shadow">
                <h2 className="text-lg font-bold mb-2">{lottery.name}</h2>
                <div className="text-sm text-gray-600 space-y-1">
                  <p>
                    <strong>ID:</strong> {lottery.id}
                  </p>
                  <p>
                    <strong>Cartones Pagados/Vendidos/Totales:</strong>{" "}
                    {lottery.paidCardsQty} / {lottery.soldCardsQty} /{" "}
                    {lottery.cardsQty}
                  </p>
                  <p>
                    <strong>Precio del Carton:</strong> {lottery.cardPrice}{" "}
                    {lottery.currency}
                  </p>
                  <p>
                    <strong>Rondas Totales:</strong> {lottery.rounds.length}
                  </p>
                  <p>
                    <strong>Fecha de Sorteo:</strong>{" "}
                    {DateTime.fromISO(lottery.drawDate)
                      .setLocale("es")
                      .toFormat("EEEE, d LLL yyyy h:mm a")}
                  </p>
                  <p>
                    <strong>Estado:</strong> {calculateLotteryState(lottery)}
                  </p>
                </div>

                {/* Caja para las rondas */}
                {lottery.rounds.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-md font-semibold mb-2">Rondas</h3>
                    {lottery.rounds.map((round, index) => (
                      <p key={round.id} className="text-sm mb-2">
                        <strong>Ronda {index + 1}:</strong>{" "}
                        <span>
                          Combinación Ganadora: {round.winningCombination.name},{" "}
                        </span>
                        <span>Premio: {round.award}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>
              {lotteryError[lottery.id] && (
                <p className="text-red-500 m-2">{lotteryError[lottery.id]}</p>
              )}
              {/* Acciones */}
              <div className="flex flex-wrap gap-2 m-2">
                <button
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  onClick={() =>
                    navigate(`/vendedor/loteria/${lottery.id}/venderCartones`, {
                      state: { lottery: lottery },
                    })
                  }
                >
                  <FaCashRegister className="w-6 h-6" />
                </button>
                <button
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  onClick={() =>
                    navigate(`/vendedor/loteria/${lottery.id}/buscarJugador`, {
                      state: { lottery: lottery },
                    })
                  }
                >
                  <FaSearch className="w-6 h-6" />
                </button>
                <a
                  target="_blank"
                  href={`/loteria/${lottery.id}?sellerId=${userId}`}
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  rel="noreferrer"
                >
                  <FaStore className="w-6 h-6" />
                </a>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">Cargando...</div>
        )}
      </div>

      {/* Paginación */}
      <Paginator
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        totalPages={totalPages}
      />
    </div>
  );
};

export default LotteriesSellerPage;
