import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Players from "../components/organizer/players";
import { ILottery } from "../common/interfaces";
import { Helmet } from "react-helmet";
import Loading from "../components/loading";

const SearchPlayerPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const lottery = location.state?.lottery as ILottery;

  useEffect(() => {
    if (!lottery) {
      navigate("/organizador/loterias", { replace: true });
    }
  });

  if (!lottery) {
    return <Loading />;
  }

  return (
    <div
      className="min-h-dvh"
      style={{
        margin: 0,
        padding: 0,
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Asegura que los elementos inicien desde la parte superior
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>
          Buscar Jugador en Lotería {lottery.name} - Loterías Guacamaya
        </title>
      </Helmet>
      {/* Título */}
      <header
        className="w-full bg-white shadow-md p-4 flex justify-between items-center"
        style={{
          position: "fixed", // Fija el header
          top: 0, // Lo coloca en la parte superior
          left: 0, // Asegura alineación con el borde izquierdo
          zIndex: 1000, // Mantiene el header encima de otros elementos
        }}
      >
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-green-500 hover:text-green-700 font-bold"
        >
          Atrás
        </button>
        <h1 className="text-xl font-semibold text-gray-700">Buscar Jugador</h1>
        <div className="w-8" />
      </header>

      {/* Contenido principal */}
      <div
        className="w-full max-w-3xl rounded-lg space-y-6"
        style={{
          marginTop: "72px", // Compensa la altura del header fijo
          padding: "1rem", // Espaciado adicional para el contenido
        }}
      >
        {/* Componente de jugadores */}
        <section>
          <Players lottery={lottery} />
        </section>
      </div>
    </div>
  );
};

export default SearchPlayerPage;
