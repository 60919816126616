import React, { useEffect } from "react";
import { IRound, IToken } from "../../common/interfaces";
import MasterCard from "./mastercard";

interface MyComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  allTokens: IToken[];
  currentRound: IRound;
}

const OrganizerRightColumn: React.FC<MyComponentProps> = ({
  isVisible,
  setIsVisible,
  allTokens,
  currentRound,
}) => {
  useEffect(() => {
    if (isVisible) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling
      document.body.style.overflow = "auto";
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  return (
    <div
      className={`bg-black/50 w-full xl:bg-dodgerBlue-500 xl:w-1/4 h-full fixed top-0 right-0 z-30 flex justify-center transition-transform duration-300 ${
        isVisible || "xl:translate-x-0 translate-x-full"
      }`}
      style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
    >
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-2 right-2 w-8 h-8 bg-red-500 text-white rounded-full xl:hidden z-30"
      >
        ×
      </button>

      <MasterCard allTokens={allTokens} currentRound={currentRound} />
    </div>
  );
};

export default OrganizerRightColumn;
