import React, { useState } from "react";
import ReactPlayer from "react-player";
import { IPopUp } from "../common/interfaces";

interface MyComponentProps {
  type: "video" | "html";
  data: any;
  setPopUp: React.Dispatch<React.SetStateAction<IPopUp | undefined>>;
}

const PopUp: React.FC<MyComponentProps> = ({ type, data, setPopUp }) => {
  return (
    <div
      className="fixed flex overflow-y-auto flex-col items-center justify-center bg-black z-50"
      style={{ height: "100dvh", width: "100vw" }}
    >
      {/* Botón de cerrar */}
      <button
        onClick={() => setPopUp(undefined)}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Continuar
      </button>
      <div className="w-full h-full">
        {type === "video" && (
          <ReactPlayer url={data} width={"100%"} height={"100%"} controls />
        )}
        {type === "html" && (
          <div
            className="w-full h-full"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        )}
      </div>
    </div>
  );
};

export default PopUp;
