import React from "react";
import { ICard, IToken } from "../common/interfaces";
import Token from "./token";

interface MyComponentProps {
  card: ICard;
  drawnTokens?: IToken[];
  winningCombination?: number[];
  showSerial: boolean;
}

const Card: React.FC<MyComponentProps> = ({
  card,
  drawnTokens,
  winningCombination,
  showSerial,
}) => {
  var localCard = {
    ...card,
    tokens: card.tokens.map((token) => ({
      ...token,
      drawnDate:
        drawnTokens?.find((dt) => dt.id === token.id)?.drawnDate || null,
    })),
  };

  const rows = [];
  for (let i = 0; i < localCard.tokens.length; i += 5) {
    rows.push(localCard.tokens.slice(i, i + 5));
  }

  return (
    <div className="flex flex-col items-center w-[350px] max-w-[100%]">
      {/* Serial centrado y en negritas */}
      {showSerial && (
        <div className="text-center">
          <span className="font-bold text-sm">Serial: {card.serial}</span>
        </div>
      )}

      {/* Cartón */}
      <div
        className="flex-none grid place-items-center bg-white rounded-lg"
        style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", color: "black" }}
      >
        <div className="grid grid-cols-5 gap-[2px] p-1">
          {rows.flat().map((token, index) => (
            <Token
              key={index}
              name={token.name}
              checked={!!token.drawnDate}
              illuminated={
                winningCombination?.includes(index) || !winningCombination
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
