import { spritePositions } from "./consts";
import { tokensSpriteUrl } from "./consts";
import { ILottery, ILotterySeller, IToken } from "./interfaces";
import { Role } from "./types";
import { NavigateFunction } from "react-router-dom";

// Precargar imágenes
export const preloadTokensSprite = async () => {
  await new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.src = tokensSpriteUrl;
    img.onload = () => {
      console.log(`Tokens sprite loaded from server`);
      resolve();
    };
    img.onerror = () => {
      reject();
      console.warn(`Failed to load tokens sprite`);
    };
  });
};

export const extractTokenImageFromSprite = async (
  name: string,
  cache: Map<string, string>,
): Promise<string> => {
  if (cache.has(name)) {
    return cache.get(name)!;
  }

  return new Promise((resolve, reject) => {
    const sprite = new Image();
    sprite.src = tokensSpriteUrl;

    sprite.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 120;
      canvas.height = 120;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject("No se pudo obtener el contexto del canvas");
        return;
      }

      const coordinates = spritePositions[name.split(" ").join("_")] ?? {
        x: -999,
        y: -999,
      };

      ctx.drawImage(
        sprite,
        coordinates.x,
        coordinates.y,
        120,
        120,
        0,
        0,
        120,
        120,
      );
      const imageData = canvas.toDataURL("image/png");

      cache.set(name, imageData);
      resolve(imageData);
    };

    sprite.onerror = (err) => reject(err);
  });
};

export const fetchFromServer = async (
  method: "GET" | "POST" | "PUT" | "DELETE",
  endpoint: string,
  body?: any,
  role?: Role,
  navigate?: NavigateFunction,
) => {
  try {
    const headers: { "Content-Type": string; Authorization?: string } = {
      "Content-Type": "application/json",
    };

    if (role) {
      headers.Authorization = `Bearer ${localStorage.getItem(`${role}_accessToken`)}`;
    }

    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      body:
        method === "GET"
          ? undefined
          : JSON.stringify(body, (key, value) => {
              // Omitir valores vacíos (null, undefined o cadenas vacías)
              if (value === "" || value === null || value === undefined) {
                return undefined; // Si el valor es vacío, omítelo
              }
              return value; // De lo contrario, mantén el valor
            }),
      credentials: role ? "include" : "omit",
      headers: headers,
    });

    if (response.status === 401) {
      localStorage.removeItem(`${role}_accessToken`);
      if (navigate) {
        if (role === "organizer") {
          navigate("/organizador/login");
        } else if (role === "player") {
          navigate("/");
        }
      }
    }

    return response;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};

export const speak = (text: string) => {
  window.speechSynthesis.cancel();
  const utterance = new SpeechSynthesisUtterance(text);
  utterance.lang = "es-ES";
  console.log(`Speak: ${text}`);
  window.speechSynthesis.speak(utterance);
};

export const handleSharePass = async (lottery: ILottery, pass: string) => {
  if (navigator.share) {
    // Verifica si la API de Web Share está disponible
    try {
      await navigator.share({
        title: `Loteria ${lottery.name}`,
        text: `Gracias por participar en lotería ${lottery.name}. Tu pase es: ${pass} y puedes acceder desde: `,
        url: `https://guacamayalote.online/loteria/${lottery.id}/entrar/${pass}`, // URL que se va a compartir
      });
    } catch (error) {
      console.error("Error al compartir:", error);
    }
  } else {
    alert(
      "La funcionalidad de compartir no está disponible en este dispositivo.",
    );
  }
};

export const handleShareShowcase = async (
  lottery: ILottery,
  seller?: ILotterySeller,
) => {
  if (navigator.share) {
    // Verifica si la API de Web Share está disponible
    try {
      await navigator.share({
        title: `Loteria ${lottery.name}`,
        text: `¡Échale un ojo a lotería ${lottery.name}! Adquiere hoy mismo los cartones que te gusten. `,
        url:
          `https://guacamayalote.online/loteria/${lottery.id}` + seller
            ? `?sellerId=${seller?.id}`
            : "",
      });
    } catch (error) {
      console.error("Error al compartir:", error);
    }
  } else {
    alert(
      "La funcionalidad de compartir no está disponible en este dispositivo.",
    );
  }
};

export const fetchAllTokens = async () => {
  const response = await fetchFromServer("GET", `/tokens/all`);

  if (!response.ok) {
    throw new Error("Error en la solicitud");
  }

  const tokens: IToken[] = await response.json();

  return tokens;
};
