import React, { useEffect, useState } from "react";
import { IEarlyPayPlayer } from "../common/interfaces";
import { fetchFromServer } from "../common/functions";
const { DateTime } = require("luxon");

interface MyComponentProps {
  lotteryId: number;
}

const EarlyPayPlayersList: React.FC<MyComponentProps> = ({ lotteryId }) => {
  const [players, setPlayers] = useState<IEarlyPayPlayer[]>();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchEarlyPayPlayers = async () => {
      const response = await fetchFromServer(
        "GET",
        `/lotteries/${lotteryId}/earlyPayPlayers`,
      );

      if (response.ok) {
        const earlyPayPlayers: IEarlyPayPlayer[] = await response.json();
        setPlayers(earlyPayPlayers);
      }
    };

    fetchEarlyPayPlayers();
  }, [lotteryId]);

  const removeAccents = (str: string): string =>
    str
      .normalize("NFD") // Descompone letras con tilde en letra + tilde
      .replace(/[\u0300-\u036f]/g, "") // Elimina los signos diacríticos (tildes, diéresis)
      .toLowerCase();

  const filteredPlayers = players?.filter((player) =>
    removeAccents(player.name).includes(removeAccents(searchTerm)),
  );

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl font-bold mb-4 text-center">
        Participantes Pronto Pago
      </h2>

      <img
        className="w-24 mx-auto mb-4"
        src="/earlyPayLogo.png"
        alt="Early Pay"
      />

      <input
        type="text"
        placeholder="Buscar por nombre..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 px-3 py-2 border border-gray-300 rounded-md text-sm w-full"
      />

      {/* Aquí va todo el contenido que debe hacer scroll */}
      <div className="flex-1 overflow-y-auto text-center pr-2">
        {filteredPlayers ? (
          filteredPlayers.length > 0 ? (
            filteredPlayers.map((earlyPayPlayer) => (
              <div
                key={earlyPayPlayer.id}
                className="mb-3 p-3 rounded-lg bg-gray-100 shadow-sm"
              >
                <p className="text-sm font-semibold text-gray-800">
                  {earlyPayPlayer.name}{" "}
                  <span className="text-gray-500">({earlyPayPlayer.id})</span>
                </p>
                <p className="text-xs text-gray-600 mt-1">
                  Pago recibido: {DateTime.fromISO(earlyPayPlayer.payment_date)
                              .setLocale("es")
                              .toFormat("EEEE, d LLL yyyy")}
                </p>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500 text-center">
              No se encontraron resultados.
            </p>
          )
        ) : (
          <div>Cargando...</div>
        )}
      </div>
    </div>
  );
};

export default EarlyPayPlayersList;
